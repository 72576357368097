.c-filters {
    &[data-custom-color="true"]{
        .c-form {
            &-select {
                &__option {
                    &--is-focused {
                        color: #333333;
                    }
                    &--is-selected {
                        color: $medium-grey;
                    }
                }
                &__menu-list--is-multi {
                    .c-form-select__option {
                        &:after {
                            background-color: $dark-grey;
                        }
                    }
                }
            }
        }
    }
    &Flex {
        @include flex(flex-start, center);
        margin-left: -8px;
        margin-right: -8px;
        @include m991 {
            display: block;
        }
        > * {
            margin-left: 8px;
            margin-right: 8px;
        }
    }
    &-label {
        white-space: nowrap;
        @include formEl();
        @include m991 {
            margin-bottom: 16px;
        }
    }
    .c-form {
        &-selectCtn {
            flex: 0 1 280px;
            min-width: 200px;
            @include m991 {
                margin-top: 10px;
            }
            &:after {
                color: $black;
            }
        }
        &-select {
            color: $black;
        }
    }
}