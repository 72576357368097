.customColors {
    //Primary
    .title-50, .title-40, .main__content h2:not([class*="title-"]), .main__content h3:not([class*="title-"]) {
        color: var(--primary-color);
    }

    .main__content-primary .buttons-list .c-button {
        @include button($white);
        color: var(--secondary-color);
        @include m1025 {
            &:hover,
            &:focus-within {
                color: $white !important;
                background-color: var(--secondary-color);
                border-color: var(--secondary-color);
            }
        }
    }

    .c-blog--post--list:before {
        display: none;
    }

    .main__content-primary .c-button {
        border-color: var(--primary-color);
        background-color: var(--primary-color);
    }

    button[type="submit"],
    .t-unsubscribe {
        @include button(var(--primary-color));
    }

    .c-news {
        &Item {
            &-content {
                border-color: var(--primary-color);

                .title-40, p {
                    color: var(--primary-color);
                }

                .c-button {
                    @include button(var(--primary-color));
                }
            }
        }
    }

    .c-job-sectors {
        &-slider {
            a {
                &:before {
                    background-color: rgba(var(--primary-color-rgb), .3);
                }

                @include m1025 {
                    &:hover, &:focus-within {
                        &:before {
                            background-color: var(--primary-color);
                        }
                    }
                }
            }
        }

        .c-button {
            margin-top: -15px;
            @include button(var(--primary-color));
        }
    }

    .c-tint {
        &-links {
            a {
                border-color: var(--primary-color);
                background-color: var(--primary-color);
                @include m1025 {
                    &:hover, &:focus-within {
                        i {
                            color: var(--primary-color);
                        }
                    }
                }
            }
        }
    }

    .main__header[data-color="blue"] .main__header-intro .title-50 {
        color: var(--primary-color);
    }

    &[data-color="blue"] {
        h2:not([class*="title-"]),
        h3:not([class*="title-"]), .accordion-toggle {
            color: var(--primary-color);
        }

        ol:not(.c-group-logos):not(.c-tint-links):not(.c-share-list):not(.c-offer-infos):not(.c-faq-list) li,
        ul:not(.c-group-logos):not(.c-tint-links):not(.c-share-list):not(.c-offer-infos):not(.c-faq-list) li,
        p {
            a {
                color: var(--primary-color);

                &.link {
                    &:before {
                        color: var(--primary-color);
                    }
                }
            }
        }

        ol:not(.c-group-logos):not(.c-tint-links):not(.c-share-list):not(.c-offer-infos):not(.buttons-list):not(.c-faq-list),
        ul:not(.c-group-logos):not(.c-tint-links):not(.c-share-list):not(.c-offer-infos):not(.buttons-list):not(.c-faq-list) {
            li {
                &:before {
                    background-color: var(--primary-color);
                }
            }
        }

        blockquote {
            color: var(--primary-color);

            &:before {
                color: rgba(var(--primary-color-rgb), .2);
            }
        }
    }

    .c-filters[data-color="blue"] {
        .c-form {
            &-select__control,
            &-inputSearchCtn,
            &-input {
                color: var(--secondary-color);
            }

            &-selectCtn {
                &:after {
                    color: var(--secondary-color);
                }
            }

            &-select {
                //color: $dark-blue;
                &__value-container {
                    .c-form-select__placeholder {
                        color: var(--secondary-color);
                    }

                    .c-form-select__single-value {
                        color: var(--secondary-color);
                    }
                }

                &__indicators {
                    .c-form-select__dropdown-indicator {
                        color: var(--secondary-color);
                    }
                }

                &__option {
                    &--is-focused {
                        color: var(--secondary-color);
                    }
                }
            }

            &-input,
            &-inputSearchCtn {
                input {
                    @include placeholder {
                        color: var(--secondary-color);
                    }
                }
            }

            &-inputSearchCtn {
                &:after {
                    color: var(--secondary-color);
                }
            }
        }
    }

    .main__content ul:not(.c-group-logos):not(.c-tint-links):not(.c-share-list):not(.c-offer-infos):not(.buttons-list):not(.c-faq-list) li:before {
        background-color: var(--primary-color);
    }

    .bodyContainer .c-form button[type="submit"], .c-modal-body .c-form button[type="submit"], .c-form button[type="submit"] {
        @include button(var(--primary-color));
    }

    //Secondary
    .c-offer {
        &Link {
            @include m1025 {
                &:hover, &:focus-within {
                    border-color: var(--secondary-color);
                }
            }
        }

        &Hover {
            background-color: var(--secondary-color);

            .c-button {
                background-color: var(--secondary-color);
                border-color: var(--secondary-color);
                color:$white!important;
            }
        }
    }

    .c-search-offers {
        &Remove {
            .filter-toRemove {
                @include m1025 {
                    &:not([disabled]) {
                        &:hover, &:focus-within {
                            color: var(--secondary-color);
                            border-color: var(--secondary-color);
                        }
                    }
                }
            }
        }
    }

    .c-share {
        &-list {
            @include m1025 {
                a:hover, a:focus-visible {
                    i {
                        color: var(--secondary-color);
                    }
                }
            }
        }
    }

    .t-404 {
        .main {
            background-color: var(--secondary-color);
        }

        .c-button {
            color: var(--secondary-color);
        }
    }

    .tarteaucitronServiceInputCheckbox + .tarteaucitronLabelCheckBox .tarteaucitronCheckBoxBt {
        background: var(--secondary-color);
    }

    #tarteaucitronRoot .denyTxt {
        color: var(--secondary-color);
    }

    .header__offerLink-item, .icon-heart:before, .home-career-content blockquote, .home-testimony-content blockquote, .c-testimony .c-quote-author, .error, .c-modal-link {
        color: var(--secondary-color);
    }

    .home-career-content blockquote:before, .home-testimony-content blockquote:before, .c-testimony .c-quote:after {
        color: rgba(var(--secondary-color-rgb), .35);
    }

    .home-career-content .c-button, .home-testimony-content .c-button, .c-push--pink .c-button, .c-offer-sticky-button {
        @include button(var(--secondary-color));
    }



    .c-offer-sticky-button {
        @include m1025 {
            &:hover, &:focus-within {
                color: var(--secondary-color);
            }
        }
    }

    .c-testimony .c-quote:before {
        background-color: var(--secondary-color);
    }

    // Ambassador ?
    .home-ambassadors, .home-ambassador {
        .title-50 {
            color: var(--secondary-color);
        }

        &Flex,
        &-sliderCtn {
            &:before, &:after {
                color: rgba(var(--secondary-color-rgb), .35);
            }
        }

        .c-button {
            @include button(var(--secondary-color));
        }
    }

    .home-ambassador {
        .c-ambassador {
            &-img {
                &:before {
                    color: rgba(var(--secondary-color-rgb), .2);
                }
            }
        }
    }

    .c-ambassador .c-quote-author {
        color: var(--secondary-color);
    }
}

.bodyContainer {
    .customColors {
        .c-form, .c-modal-body .c-form {
            &-select {
                &__option {
                    &--is-selected {
                        color: var(--primary-color);
                    }
                }
            }

            &-select__control,
            &-input {
                &[data-error="true"] {
                    border-color: var(--secondary-color);
                }
            }

            &-inputSearchCtn {
                &[data-error="true"] {
                    input {
                        border-color: var(--secondary-color);
                    }
                }
            }

            &-checkbox {
                &[data-error="true"] {
                    .c-form-checkbox-box {
                        border-color: var(--secondary-color);
                    }
                }
            }

            &-recaptcha {
                &[data-error="true"] {
                    border-color: var(--secondary-color);
                }
            }

            button[type="submit"] {
                @include button(var(--secondary-color));
            }
        }

        // Ambassador ?
        .home-ambassadors {
            .swiper-pagination {
                &-bullet {
                    &.swiper-pagination-bullet-active {
                        border-color: var(--secondary-color);
                        background-color: var(--secondary-color);
                    }
                }
            }
        }
    }
}