@mixin m1440 {
    @media screen and (min-width: 1440px) {
        @content;
    }
}

@mixin m1200 {
    @media screen and (min-width: 1200px) {
        @content;
    }
}

@mixin m1199 {
    @media screen and (max-width: 1199px) {
        @content;
    }
}

@mixin m1025 {
    @media screen and (min-width: 1025px) {
        @content;
    }
}

@mixin m1024 {
    @media screen and (max-width: 1024px) {
        @content;
    }
}

@mixin m991 {
    @media screen and (max-width: 991px) {
        @content;
    }
}

@mixin m767 {
    @media screen and (max-width: 767px) {
        @content;
    }
}