// Fonts import and declaration

$fontPath: '../../Assets/fonts/';

@mixin font-face($fontName, $fontFile, $fontWeight:normal, $fontStyle:normal) {
    @font-face {
        font-family: $fontName;
        src: url('#{$fontPath}#{$fontFile}.woff2') format('woff2'),
        url('#{$fontPath}#{$fontFile}.woff') format('woff');
        font-weight: $fontWeight;
        font-style: $fontStyle;
    }
}

@include font-face("Geomanist", Geomanist/Geomanist-Regular, 400);
// Icomoon see utils/icons.scss

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');