.t-offer-details {
    .main__content-primary-img{
        margin-top: 40px;
    }
    .c-button-ctn + .c-button-ctn,
    .c-button + .c-button {
        margin-top: 10px;
    }
    .main__header-introFlex--offer{
        img{
            margin-top: -15px;
            max-height: 40px;
        }
    }
    a+ .main__header-introFlex,
    img +.main__header-introFlex{
        margin-top: 15px;
    }
    .c-video + .description{
        margin-top: 50px;
    }
    .description h2:not(:first-child){
        @include m1025{
            margin-top: 42px;
        }
    }
    .description+.description{
        margin-top: 12px;
    }
    .accordion-toggle{
        cursor:pointer;
        @include flex(space-between, flex-start);
        &.active{
            &:after{
                transform: rotate(180deg);
            }
        }
        &:after{
            position: relative;
            top: 10px;
            font-size: 10px;
            content: "\e902";
            @include icomoon();
            color:inherit;
        }
    }

    .rc-collapse{
        position: relative;
        height: 0;
        overflow: hidden;
        transition: height 0.35s ease;
    }
    .rc-collapse.show{
        height: auto;
    }
}