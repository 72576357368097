.c-blog {
  display: flex;
  flex-direction: column;
  border-radius: 22px;
  text-align: left;
  padding: 30px 38px 26px;
  color: $white;
  background: var(--secondary-color);
  margin-top: 36px;
  &--title {
    font-size: 40px;
  }
  &--separator {
    background: $white;
    margin: 15px 0;
  }
  &--post {
    margin: 0 0 30px 0 !important;
    &--list {
      padding: 0 !important;
      &--link {
        color: $white !important;
        &:hover {
          color: $dark-grey !important;
        }
      }
    }
    &--separator {
      background: $white;
      margin: 15px 0;
    }
  }
  &--button {
    border-radius: 19px;
    padding: 10px 20px;
    color: var(--secondary-color);
    background: $white;
    align-self: start;
    font-size: 14px;
    font-weight: 500;
    border: 2px solid #ffffff;
    transition: all 0.35s ease-in-out;
    &:hover {
      color: $white;
      background: transparent;
    }
  }
}
