.t-testimony-details {
    .main {
        &__header {
            &-intro {
                h1 {
                    font-family: $fontText;
                    font-weight: 700;
                }
            }
        }
    }
}