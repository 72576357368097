@import "elevator-generic.scss";

.elevator {
    z-index: 0;
    position: relative;
    width: 100%;
    height: 501px;
    font-size: 10px;
    background: $colorBackground;

    
    &[data-custom-color="true"] {
        background: inherit;
    }
    &[data-custom-color="false"] {
        .intern_website & {
            background: $internColorBackground;
        }
    }

    overflow: hidden;
    max-height: 501px;
    transition: max-height .35s ease-in-out;

    @include m1199 {
        /* z-index: 7;
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         max-height: 0;*/
    }
    > * {
        opacity: 1;
        transition: opacity .35s ease-in-out, max-height .35s ease-in-out;
    }
    [data-mini-remove="true"] &,
    [data-sticky="true"] & {
        max-height: 0;
        transition: max-height .35s ease-in-out;
        //@include m1200 {
        height: auto;
        //}
        > * {
            max-height: 0;
            opacity: 0;
        }

        &[data-sticky-hover="false"] {

        }

        &[data-sticky-hover="true"] {
            max-height: 501px;
            height: auto;
            transition: none;
            > .elevator__sticky {
                overflow: visible;
                max-height: 501px;
                opacity: 1;
                // transition: max-height .35s ease-in-out, opacity .35s ease-in-out;
                &:before {
                    opacity: 1;
                    transition: opacity .35s ease-in-out .25s;
                }
            }

        }

    }
    [data-show-elevator="true"] & {
        @include m1199 {
            /*  max-height: calc(100% - 48px);
              transition: max-height .35s ease-in-out;
              .elevator__moteur {
                  max-height: 100%;
                  opacity: 1;
              }*/
        }
    }

    .container {
        text-align: center;
    }

    &__moteur {
        position: relative;
        z-index: 2;
    }
    &__moteur {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include m1199 {
            position: relative;
            z-index: 1;
        }
    }

    &__sticky,
    &__message {
        color: $colorTexte;
        font-size: 3.1em;
        line-height: 1.3;
        font-family: "Geomanist";
        text-align: center;
    }

    &__message {
        font-size: 2.5em;
        line-height: 1.2em;
        width: 674px;
        max-width: 80%;
    }

    &--closed &__message, &__sticky {
        &:before {
            opacity: 0;
            z-index: -1;
            position: absolute;
            bottom: -52px;
            left: 50%;
            transform: translateX(-50%);
            content: '';
            display: block;
            width: 477px;
            height: 141px;
            background-image: url('../../Assets/img/sticky-waves.png');
            background-position: top center;
            background-repeat: no-repeat;
            background-size: 100% auto;
            .intern_website & {
                background-image: url('../../Assets/img/sticky-waves-intern.png');
            }
            @include m1199 {
                bottom: auto;
                top: calc(50% - 30px);
                transform: translate(-50%, -50%);
            }
        }
    }

    &--closed {
        z-index: 6;

        // @include m1200 {
        z-index: 3;
        overflow: visible;
        height: auto;
        // }
        [data-mini-remove="true"] &,
        [data-sticky="true"] & {
            //@include m1200 {
            z-index: 0;
            // }
        }
        .elevator__message {
            &:before {
                opacity: 1;
                transition: opacity .35s ease-in-out;
            }
        }
    }

    &--closed &__moteur {
        // @include m1200 {
        height: auto;
        // }
        @include m1199 {
            //z-index: -1;
        }
    }

    &--closed &__message {
        padding-top: 15px;
        font-size: 35px;
        line-height: 1;
        width: 674px;
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;

        @include m1199 {
            padding-top: 40px;
            padding-bottom: 30px;
        }

        &--small, span {
            font-size: 25px;
            line-height: 1.2em;
        }
        span {
            display: block;
        }
    }

    &__sticky {
        //display: none;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        transition: max-height .5s ease-in-out, opacity .5s ease-in-out, overflow 0s linear .5s;

        &:before {
            width: 400px;
            bottom: -20px;
            transition: opacity .35s ease-in-out;
        }

        span {
            display: block;
            max-width: 674px;
            margin-left: auto;
            margin-right: auto;
            padding-top: 42px;
            padding-bottom: 35px;
        }
        &--small {
            font-size: 2.5em;
            line-height: 1.2em;
        }
    }

    &__resetCtn {
        position: relative;
    }
    &__reset {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: absolute;
        max-width: 125px;
        bottom: 0;
        left: 0;
        font-size: 12px;
        font-weight: 600;
        color: #fff;
        transition: color .35s ease-in-out;
        @include m1199 {
            position: relative;
            padding-bottom: 40px;
            margin-top: 40px;
            margin-left: auto;
            margin-right: auto;
        }
        i {
            display: block;
            margin-right: 8px;
            font-size: 20px;
            transition: transform .35s ease-in-out;
        }
        span {
            text-align: left;
        }
        &:hover {
            @include m1025 {
                color: rgba(255, 255, 255, .8);
                i {
                    transform: rotate(180deg);
                }
            }
        }
    }

    /*.header--sticky & {
        height: auto;
        min-height: 0px;

        &__moteur {
            height: 1px;
            opacity: 0;
            pointer-events: none;
        }

        &__sticky {
            display: block;
            opacity: 0;
            transition: opacity 0.35s ease;

            &--visible {
                opacity: 1;
            }
        }
    }
*/
    &__ctaStart__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        text-align: center;
        width: 100%;
        height: 100%;
        background-image: url('./../../Assets/img/wave-intro.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 0px 5px;
        @include m1440 {
            background-size: 100% 100%;
        }
        .elevator__ctaStart--title {
            font-size: 45px;
            line-height: 50px;
            @include m1024 {
                font-size: 38px;
                margin-bottom: 5px;
            }
            @include m767 {
                font-size: 30px;
            }
        }
        .elevator__ctaStart--text {
            font-size: 35px;
            line-height: 50px;
            margin-bottom: 50px;
            @include m1024 {
                font-size: 30px;
                line-height: 35px;
                margin-bottom: 40px;
            }
            @include m767 {
                font-size: 22px;
                line-height: 26px;
            }
        }
    }
    &__ctaStart {
        @include elevator-btn;
        &:focus {
            background: rgba(255, 255, 255, 0.3);
        }
    }

    @include m1024 {
        font-size: 8px;
    }

    @include m991 {
        font-size: 6px;
    }

    @include m767 {
        font-size: 1.5vw;
        //height: 410px;
        //max-height: 410px;
    }
}