@import "../../Styles/utils/vars";
@import "../../Styles/utils/mixins";
@import "elevator-generic.scss";

.elevator {
    &__box {
        width: 100%;
        height: 100%;
        background: $colorBackground;

        &[data-custom-color="true"] {
            background: inherit;
        }
        &[data-custom-color="false"] {
            .intern_website & {
                background: $internColorBackground;
            }
        }

        overflow: hidden;
        position: relative;
        font-family: "Geomanist";
        box-sizing: border-box;


        &:before,
        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            height: 100px;
            z-index: 3;
            pointer-events: none;
        }

        &[data-custom-color="false"] {
            &:before {
                top: 0;
                background: linear-gradient(to top, transparent 50%, $colorBackground 100%);
                .intern_website & {
                    background: linear-gradient(to top, transparent 50%, $internColorBackground 100%);
                }
            }
    
            &:after {
                bottom: 0;
                background: linear-gradient(to bottom, transparent 50%, $colorBackground 100%);
                .intern_website & {
                    background: linear-gradient(to bottom, transparent 50%, $internColorBackground 100%);
                }
            }
        }

        .c-button {
            z-index: 5;
            position: absolute;
            right: 100px;
            bottom: 30px;
            @include button($white, var(--primary-color));
            color: var(--primary-color);
            font-size: 13px;
            .intern_website & {
                @include button($white, $intern);
            }
            @include m767 {
                right: 38px;
                padding-left: 18px;
                padding-right: 18px;
            }
            @include m374 {
                font-size: 11px;
            }
            &--prev, &--next {
                opacity: 0;
                visibility: hidden;
                transition: color .35s ease-in-out, background-color .35s ease-in-out, opacity .35s ease-in-out, visibility 0s linear .35s;
            }
            &--current {
                opacity: 1;
                visibility: visible;
                transition: color .35s ease-in-out, background-color .35s ease-in-out, opacity .35s ease-in-out, visibility 0s linear;
                &.c-button--disabled {
                    opacity: 0;
                    visibility: hidden;
                    transition: color .35s ease-in-out, background-color .35s ease-in-out, opacity .35s ease-in-out, visibility 0s linear .35s;
                }
            }
        }

        .elevator__sound {
            z-index: 5;
            position: absolute;
            left: 0;
            bottom: 0;
            font-size: 20px;
            button {
                padding: 30px;
                &.sound-disabled {
                    i:before {
                        content: "\e91f";
                    }
                }
            }
        }
    }

    &__etape {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        overflow: auto;

        scrollbar-color: #000 transparent;
        scrollbar-width: thin;

        opacity: 0;
        transition: transform 1s cubic-bezier(1, 0, 0, 1), opacity 0.75s ease;
        text-align: center;
        padding-bottom: 21px;
        box-sizing: border-box;

        display: flex;
        justify-content: center;
        align-items: center;

        @include m767 {
            padding-bottom: 75px;
        }

        &Box {
            padding: 50px 0;
            max-width: 60%;
            margin: auto;
            @include m991 {
                max-width: 80%;
            }
        }

        &:first-child {
            position: relative;
        }

        &--prev {
            transform: translateY(-100%);
        }

        &--current {
            transform: translateY(0%);
            opacity: 1;
            z-index: 2;
        }

        &--next {
            transform: translateY(100%);
        }
    }

    &__regle {
        position: absolute;
        right: 36px;
        top: 25px;
        bottom: 30px;
        width: 8px;
        height: auto;
        border: solid 1px $colorTexte;
        border-radius: 5px;
        box-sizing: border-box;
        z-index: 4;

        &__titre {
            position: absolute;
            right: calc(100% + 25px);
            font-size: 1.5em;
            color: $colorTexte;
            text-transform: uppercase;
            font-weight: 500;
            transform-origin: right top;
            transform: rotate(-90deg);
            letter-spacing: 0.02em;
        }

        &__curseur {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 0%;
            background-color: $colorTexte;
            border-radius: 5px;
            transition: height 0.35s linear;
        }
    }

    &__option {
        margin-top: 8px;
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;

        &--first {
            margin-top: 45px;
        }

        &--last {
            margin-bottom: -37px;
        }

        button {
            @include elevator-btn;

            span {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                padding: 6px 47px 11px;
                color: $colorBackground;
                opacity: 0;
                pointer-events: none;
                z-index: 1;
                transition: opacity 0.35s ease 0.35s;
                .intern_website & {
                    color: $internColorBackground;
                }
            }

            &:after {
                content: "";
                position: absolute;
                left: -1px;
                right: -1px;
                top: -1px;
                bottom: -1px;
                background-color: #fff;
                transform: scale(0);
                transition: transform 0.35s ease;
                border-radius: 35px;
            }

            &.selected {
                span {
                    opacity: 1;
                }

                &:after {
                    transform: scale(1);
                }
            }
        }
    }

    &__formName {
        margin-top: 15px;
        margin-bottom: -60px;

        input {
            background-color: transparent;
            border: none;
            border-bottom: solid 3px $colorTexte;
            font-size: 4.0em;
            font-family: "Geomanist";
            line-height: 1.5em;
            color: $colorTexte;
            text-align: center;
            width: 380px;
            padding: 9px;

            &:focus {
                outline: none;
            }
        }

        button {
            display: none;
            margin: 20px auto 0;
            font-size: 3.0em;

            @include elevator-btn;
        }
    }

    &__texte {
        color: $colorTexte;
        font-size: 4.0em;
        line-height: 1.25em;
        font-family: "Geomanist";

        &--big {
            font-size: 10.0em;
            line-height: 0.7em;
            margin-bottom: 22px;
        }
    }

    &__name {
        color: $colorBackground;
        background-color: $colorTexte;
        border-radius: 50px;
        padding: 8px 45px 13px;
        display: inline-block;
        margin: 30px 0 38px;
        .intern_website & {
            color: $internColorBackground;
        }
    }

    @include m1025 {
        ::-webkit-scrollbar {
            width: 4px;
            height: 4px;
        }

        ::-webkit-scrollbar-button {
            width: 0px;
            height: 0px;
        }

        ::-webkit-scrollbar-thumb {
            background: #999999;
            border: 0px none #ffffff;
            border-radius: 50px;
        }

        ::-webkit-scrollbar-thumb:hover {
            background: #999999;
        }

        ::-webkit-scrollbar-thumb:active {
            background: #999999;
        }

        ::-webkit-scrollbar-track {
            background: transparent;
            border: 0px none #000000;
            border-radius: 50px;
        }

        ::-webkit-scrollbar-track:hover {
            background: transparent;
        }

        ::-webkit-scrollbar-track:active {
            background: transparent;
        }

        ::-webkit-scrollbar-corner {
            background: transparent;
        }
    }

    @include m1024 {
        &__formName {
            margin-top: 15px;
            margin-bottom: -30px;

            input {
                border-bottom: solid 2px $colorTexte;
                width: 320px;
                padding: 6px;
            }
        }

        &__option {
            margin-top: 4px;

            &--first {
                margin-top: 30px;
            }
        }
    }

    @include m767 {
        &__regle {
            right: 11px;

            &__titre {
                font-size: 2.0em;
            }

        }

        &__formName {
            margin-top: 10px;
            margin-bottom: -30px;

            input {
                border-bottom: solid 2px $colorTexte;
                width: 200px;
                padding: 6px;
            }

            button {
                display: block;
            }
        }

        &__option {
            margin-top: 2px;

            &--first {
                margin-top: 20px;
            }

            button {
                padding: 6px 17px 6px;

                span {
                    padding-left: 17px;
                    padding-right: 17px;
                }
            }
        }
    }
}