.static-header {
  width: 100%;
  height: 100%;
  min-height: calc(501px + 85px);
  margin-top: -85px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  background-size: cover;
  background-position: center;
  padding: 60px;

  @include m479 {
    padding: 30px;
  }

  @include m1025 {
    padding: 90px;
  }


  &[data-sticky="true"] {
      display: none;
  }

  &__title {
    width: 80%;
    font-size: 60px;
    line-height: 70px;
    color: $white;
    font-weight: bold;
    font-size: 40px;
    line-height: 45px;

    @include m479 {
      font-size: 25px;
      line-height: 35px;
      width: 100%;
    }

    @include m1025 {
      width: 60%;
      font-size: 60px;
      line-height: 70px;
    }

  }
}