.c-job {
    display: block;
    margin-top: 44px;
    @include m767 {
        max-width: calc(100% + 20px);
        min-width: calc(100% + 20px);
        margin-left: -10px;
        margin-right: -10px;
        margin-top: 13px;
    }
    @include m1025 {
        &:hover, &:focus-within {
            .c-job {
                &-img {
                    &:before {
                        background-color: rgba(var(--secondary-color-rgb), .8);
                    }
                }
                &-content {
                    border-color: $medium-grey;
                    background-color: $white;
                    &:before {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            }
        }
    }
    &-img {
        display: block;
        position: relative;
        overflow: hidden;
        &:before {
            position: relative;
            z-index: 1;
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 60%;
            background-color: rgba(var(--secondary-color-rgb), .3);
            transition: background-color .35s ease-in-out;
        }
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: auto;
            height: 100%;
            transform: translate(-50%, -50%);
        }
    }
    &-content {
        display: block;
        position: relative;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        border-bottom: 1px solid transparent;
        border-bottom-left-radius: 22px;
        border-bottom-right-radius: 22px;
        padding: 30px 20px 65px;
        transition: border-color .35s ease-in-out;
        @include m767 {
            padding-left: 10px;
            padding-right: 10px;
            padding-bottom: 15px;
        }

        &:before {
            opacity: 0;
            content: "\e912";
            display: block;
            position: absolute;
            bottom: 16px;
            right: 16px;
            width: 44px;
            height: 44px;
            border-radius: 50%;
            background-color: var(--secondary-color);
            text-align: center;
            color: $white;
            font-size: 15px;
            transform: scale(.2);
            transition: all .3s ease-in-out .1s;
            @include icomoon();
            line-height: 44px;
        }
    }
    p {
        font-size: 18px;
        @include m1024 {
            font-size: 16px;
        }
    }
    .title-30 + p {
        margin-top: 12px;
    }
}