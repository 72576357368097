.bodyContainer {
    .swiper-container-multirow-column {
        //  align-items: initial;

        width: calc(100% + ((100vw - 1180px) / 2));
        height: 100%;
        padding-right: 40px !important;
        margin-left: auto;
        margin-right: auto;
        @include m1099 {
            width: calc(100% + 40px);
            padding-right: 40px !important;
        }

        > .swiper-wrapper {
            //@include flex(flex-start, initial);
            max-width: calc(100% + ((100vw - 1180px) / 2)) !important;
            margin-left: 0;
            @include m1099 {
                max-width: calc(100% + 40px) !important;
            }
        }

        .swiper-slide {
            margin-top: 20px;
            width: calc(100% - 18px);
            //height: auto;
            height: calc((100% - 50px) / 2);
            //max-height: calc((100% - 10px) / 2);
            max-width: 380px;

            /* Center slide text vertically */
            //@include flex(flex-start, flex-start);
            @include flex(flex-start, initial);

            > * {
                width: 100%;
                //height: 100%;
                margin: 0;
            }
        }

    }
    .swiper-container:not(.swiper-container-multirow-column) {
        width: calc(100% + ((100vw - 980px) / 2));
        padding-right: calc((100vw - 980px) / 2) !important;
        height: 100%;
        padding-bottom: 60px !important;
        @include m1024 {
            width: calc(100% + 20px);
            padding-right: 35px !important;
        }

        .swiper-wrapper {
            align-items: center;

            .swiper-slide {
                width: auto !important;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                @include m1024 {
                    width: 100% !important;
                }
                .item-img {
                    max-width: 100%;
                    height: 100%;
                    width: auto;
                    transition: transform 0.3s linear;
                    @include m1024 {
                        width: 100%;
                        height: auto;
                    }
                    &.reduce {
                        transform: scale(0.9);
                        transition: transform 0.3s linear;
                    }
                }
                .c-testimony, .c-push {
                    @include m767 {
                        margin-left: 0;
                        margin-right: 0;
                        width: 100%;
                    }
                }
                .c-push {
                    @include m1024 {
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }

    [data-bg=grey] {
        .swiper-pagination {
            &-bullet {
                border: solid 2px #bfbfbf;
            }
        }
    }

    .c-news {
        .swiper-container-horizontal > .swiper-pagination-bullets {
            width: 100%;
        }
    }
    .swiper-container-horizontal > .swiper-pagination-bullets {
        @include flex(center, center);
        width: calc(100% - 35px);
        @include m1199 {
            width: 100%;
        }
        @include m1025 {
            display: none;
        }
        .swiper-pagination-bullet {
            margin: 0 6px;
        }
    }
    .swiper-pagination-bullet:only-child {
        visibility: hidden;
    }
    .swiper-pagination {
        &-bullet {
            opacity: 1;
            width: 10px;
            min-width: 10px;
            height: 10px;
            border: solid 2px $medium-grey;
            background-color: transparent;
            &.swiper-pagination-bullet-active {
                width: 19px;
                min-width: 19px;
                height: 19px;
                border-color: var(--secondary-color);
                background-color: var(--secondary-color);
            }
        }
    }

    .c-testimonies {
        .swiper-pagination {
            &-bullet {
                &.swiper-pagination-bullet-active {
                    border-color: $dark-blue;
                    background-color: $dark-blue;
                }
            }
        }
    }
    .home-ambassadors {
        .swiper-pagination {
            &-bullet {
                &.swiper-pagination-bullet-active {
                    border-color: $blue;
                    background-color: $blue;
                }
            }
        }
    }

    .c-job-sectors {
        .swiper-pagination {
            @include m1024 {
                display: none;
            }
        }
    }

    .swiper-button-prev, .swiper-button-next {
        top: auto !important;
        bottom: 0 !important;
        width: 7px !important;
        height: 13px !important;
        margin: 0 !important;
        color: var(--secondary-color) !important;
        @include m1024 {
            display: none;
        }
        &:after {
            @include icomoon();
            font-size: 13px !important;
        }
        &.swiper-button-disabled {
            z-index: 52;
            opacity: 1 !important;
            // opacity: 0 !important;
            color: $dark-grey !important;
        }
    }
    .intern_website {
        .swiper-button-prev, .swiper-button-next {
            color: $intern !important;
        }
    }

    .swiper-button-prev {
        left: auto !important;
        right: calc((100vw - 980px) / 2 + 80px + 18px) !important;
        &:after {
            content: "\e903" !important;
        }
    }

    .swiper-button-next {
        right: calc((100vw - 980px) / 2 + 0px) !important;
        &:after {
            content: "\e904" !important;
        }
    }

    .swiper-container-horizontal > .swiper-scrollbar {
        width: 80px !important;
        right: calc((100vw - 980px) / 2 + 12px);
        left: auto !important;
        bottom: 4px !important;
        @include m1024 {
            display: none;
        }
    }

    .swiper-scrollbar {
        border-radius: 0 !important;
        background: transparent !important;
        height: 0 !important;
        .swiper-scrollbar-drag {
            background: var(--secondary-color);
            height: 1px;
            top: -3px;
            border-radius: 0 !important;
        }
    }
}