@import "../../Styles/utils/vars";
@import "../../Styles/utils/mixins";
@import "elevator-generic.scss";

.elevator_graph {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity .35s ease-in-out;
    @include m767 {
        &:before,
        &:after {
            display: block;
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            height: 30%;
            z-index: 3;
            pointer-events: none;
        }

        &:before {
            top: 0;
            background: linear-gradient(to top, transparent 0, $colorBackground 10%, $colorBackground 100%);
            .intern_website & {
                background: linear-gradient(to top, transparent 0, $internColorBackground 10%, $internColorBackground 100%);
            }
        }

        &:after {
            bottom: 0;
            background: linear-gradient(to bottom, transparent 0, $colorBackground 10%, $colorBackground 100%);
            .intern_website & {
                background: linear-gradient(to top, transparent 0, $internColorBackground 10%, $internColorBackground 100%);
            }
        }
    }
    &--hide {
        opacity: 0;
    }

    canvas {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        @include m767 {
            z-index: 1;
            width: 140%;
            height: 50%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .canvas2, .canvas3 {
        mix-blend-mode: lighten;
    }
}