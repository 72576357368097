// Variables declaration

//FONTS
$fontTitle: "Geomanist", Helvetica, Arial, sans-serif;
$fontText: 'Montserrat', Helvetica-Neue, sans-serif;

@mixin h2-title($lineHeight: 1.1) {
    font-family: $fontTitle;
    font-size: 50px;
    line-height: $lineHeight;
}

@mixin h2-title-alt($lineHeight: .9) {
    font-family: $fontText;
    font-size: 50px;
    font-weight: 700;
    line-height: $lineHeight;
}

@mixin h3-title($lineHeight: 1.2) {
    font-family: $fontTitle;
    font-size: 40px;
    line-height: $lineHeight;
}

@mixin h3-title-alt($lineHeight: 1.13) {
    font-family: $fontText;
    font-size: 40px;
    font-weight: 300;
    line-height: $lineHeight;
}

@mixin h4-title($lineHeight: 1.27) {
    font-family: $fontTitle;
    font-size: 30px;
    line-height: $lineHeight;
}

@mixin h5-title($lineHeight: 1.16) {
    font-family: $fontTitle;
    font-size: 25px;
    line-height: $lineHeight;
}

@mixin push-title() {
    font-family: $fontText;
    font-size: 22px;
    line-height: 1.36;
    font-weight: 500;
}

@mixin quote() {
    font-family: $fontText;
    font-size: 20px;
    font-style: italic;
    line-height: 1.4;
}

@mixin quote-caption() {
    font-family: $fontText;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
}

@mixin button($backgroundColor: var(--primary-color), $backgroundColorSecond: transparent) {
    background-color: $backgroundColor;
    border: 2px solid $backgroundColor;
    outline: 0 !important;

    //&[data-custom-color="false"]{
        @include m1025 {
            &:hover, &:focus-within {
                color: $backgroundColor;
                background-color: $backgroundColorSecond;
            }
        }
    //}
}

@mixin formEl($fontSize: 16px) {
    font-family: $fontText;
    font-size: $fontSize;
    font-weight: 500;
}

@mixin text($lineHeight: 1.44, $fontSize: 18px) {
    font-family: $fontText;
    font-size: $fontSize;
    line-height: $lineHeight;
}

//IMAGES
$imgPath: '../../Assets/img/';
// Use : background-image: url('#{$imgPath}menu-wave-mini.svg');

//COLORS
$black: #000000;
$white: #ffffff;
$light-grey: #f5f5f5;
$medium-grey: #b2b1b1;
$dark-grey: #8e8e8e;
$purple: #9159a4;
$intern: #655679;
$dark-purple: #5b1d76;
$pink: #e76b72;
$watermelon: #fc5263;
$blue: #129be1;
$dark-blue: #1e3567;
$green: #6abf79;
$apple-green: #87c526;

:root {
    --primary-color: #9159a4;
    --secondary-color: #5b1d76;
    --primary-color-rgb: 145,89,164;
    --secondary-color-rgb: 91,29,118;
}