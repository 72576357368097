.t-unsubscribe {
    .c-link--back {
        @include m1200 {
            margin-bottom: 115px;
        }
    }
    .main {
        padding-bottom: 40px;
    }
    .main__header-intro {
        padding-bottom: 36px;
    }
    .c-buttonCtn .c-button {
        @include button($pink);
    }
}