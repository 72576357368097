.footer {
    position: relative;
    z-index: 20;
    background-color: $white;
    &Top {
        border-top: 9px solid $light-grey;
        padding-top: 30px;
        padding-bottom: 30px;
        @include m991 {
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }
    &Bottom {
        box-sizing: border-box;
        padding: 36px 0 31px;
        background-color: var(--primary-color);
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        display: flex;
        align-items: center;
        @include m1199 {
            flex-direction: column;
        }
        .intern_website & {
            background-color: $intern;
        }
        .logo__footer {
            max-width: 100px;
            margin: 0px 2%;
            @include m1199 {
                margin: 15px auto;
            }
        }

        &Flex {
            @include flex(space-between, center, row, wrap);
            @include m767 {
                display: block;
            }
        }
    }
    &__intro {
        @include text(1.67);
        font-weight: 500;
        @include m767 {
            font-size: 15px;
            text-align: center;
        }
    }
    &__logos {
        margin-top: 22px;
        @include flex(space-between, center);
        margin-left: -15px;
        margin-right: -15px;
        @include m991 {
            flex-wrap: wrap;
            justify-content: center;
        }
        > * {
            margin-left: 15px;
            margin-right: 15px;
            height: 43px;
            min-width: 95px;
            flex: auto;
            @media all and (-ms-high-contrast: none) {
                flex: 1;
            }
            @include m991 {
                margin: 20px 15px;
                height: auto;
            }
            @include m991 {
                min-width: 1px;
                max-width: calc(25% - 40px);
            }
            @include m767 {
                max-width: calc(33.33% - 40px);
            }
            @include m479 {
                max-width: calc(50% - 40px);
            }
        }
        a {
            @include flex(center, center, column);
            height: 100%;
            width: 100%;
            @include m1025 {
                &:hover, &:focus-within {
                    img {
                        //transform: scale(1.1);
                        filter: grayscale(.8);
                    }
                }
            }
        }
        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            transition: all .35s ease-in-out;
        }
    }
    &__menu {
        @include flex(flex-start, center);
        margin-left: -11px;
        margin-right: 9px;
        @include m1199 {
            min-width: 100%;
            margin-bottom: 25px;
            margin-right: 0;
            margin-left: 0;
        }
        @include m991 {
            display: block;
            text-align: center;
        }
        > * {
            margin-left: 11px;
            margin-right: 11px;
        }
        li + li {
            @include m991 {
                margin-top: 6px;
            }
        }
        a, button {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.44;
            color: $white;
            transition: color .35s ease-in-out;
            @include m767 {
                font-size: 14px;
            }
            @include m1025 {
                &:hover, &:focus-within {
                    color: rgba($white, .7);
                }
            }
        }
    }
    &__social {
        @include flex(flex-start, center);
        margin-left: 9px;
        margin-right: 0px;
        @include m767 {
            justify-content: center;
        }
        > * {
            margin-left: 11px;
            margin-right: 11px;
            &:first-child {
                @include m1199 {
                    margin-left: 0;
                }
            }
        }
        a {
            @include flex(center, center);
            border: 2px solid $white;
            background-color: white;
            color: $white;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            transition: background-color .35s ease-in-out;
            font-size: 14px;
            @include m1025 {
                &:hover, &:focus-within {
                    outline: 0;
                    background-color: transparent;
                    i {
                        color: $white;
                    }
                }
            }
        }
    }
    &__copyright {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.44;
        color: $white;
        @include m767 {
            margin-top: 25px;
            text-align: center;
            font-size: 14px;
        }
    }

    i {
        position: relative;
        color: var(--primary-color);
        transition: color .35s ease-in-out;
        .intern_website & {
            color: $intern;
        }
    }

    .icon-instagram {
        font-weight: 600;
    }
    .icon-twitter {
        top: 1px;
    }
}