.c-keynumbers {
    padding-top: 85px;
    padding-bottom: 82px;
    border-bottom: 9px solid $light-grey;
    @include m1024 {
        padding-top: 75px;
        padding-bottom: 60px;
    }
    &-title {
        color: $black;
        @include m1024 {
            text-align: center;
        }
        img {
            display: inline-block;
            max-height: 45px;
            width: auto;
            vertical-align: middle;
            margin-right: 30px;
            @include m1024 {
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 8px;
            }
        }
    }
    &-numbers {
        @include flex(space-around, flex-start);
        margin-top: 50px;
        @include m1024 {
            display: block;
            margin-top: 0;
        }
        li {
            margin-top: 15px;
            font-family: $fontText;
            font-size: 25px;
            line-height: 0.96;
            font-weight: 300;
            text-align: center;
            @include m1024 {
                margin-top: 40px;
                font-size: 20px;
            }
            span {
                display: block;
                margin-bottom: 12px;
                font-size: 70px;
                font-weight: 700;
                line-height: 0.69;
                @include m1024 {
                    font-size: 60px;
                }
            }
        }
    }
    &-numbers + p {
        margin-top: 45px;
    }
    p {
        max-width: 680px;
    }
    &-button {
        @include m1024 {
            text-align: center;
        }
    }
    .c-button {
        margin-top: 35px;
        @include button(var(--secondary-color));
    }
}