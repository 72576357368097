.c-search-offers {
    &[data-bg="grey"] {
        padding-top: 46px;
        padding-bottom: 26px;
        background-color: $light-grey;
        @include m767 {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }
    &--main {
        margin-top: -45px;
        margin-bottom: 33px;

        @include m1024 {
            margin-top: 0;
        }
        &[data-bg=grey] {
            padding-top: 33px;
            padding-bottom: 0;
            @include m1024 {
                padding-bottom: 0;
            }
        }
        > .container {
            .c-search-offersFlex:first-child {
                justify-content: center;
                margin-left: -10px;
                margin-right: -10px;
                padding-bottom: 36px;
                @include m1024 {
                    padding-bottom: 30px;
                }
                > * {
                    margin-left: 10px;
                    margin-right: 10px;
                }
                .c-form-inputSearchCtn {
                    @include m1024 {
                        margin-bottom: 0px;
                    }
                    @include m767 {
                        margin-bottom: 20px;
                    }
                }
                .c-button {
                    position: relative;
                    &:after {
                        opacity: 0;
                        content: '';
                        display: block;
                        position: absolute;
                        top: calc(100% + 23px);
                        left: 50%;
                        transform: translateX(-50%);
                        width: 127px;
                        height: 25px;
                        background-image: url('#{$imgPath}search-wave.svg');
                        background-position: top center;
                        background-repeat: no-repeat;
                        background-size: 100% auto;
                        transition: max-height .35s ease-in-out;

                        @include m1024 {
                            top: calc(100% + 17px);
                        }
                    }
                }
            }
        }
        &.opened {
            .c-search-offersHidden {
                max-height: 999px;
                overflow: visible;
                transition: max-height .35s ease-in-out;
            }
            > .container {
                .c-search-offersFlex:first-child {
                    .c-button {
                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
        .c-search-offers-filters {
            @include m1024 {
                padding-top: 30px;
                padding-bottom: 30px;
            }
        }
        .c-form-button {
            @include m1024 {
                margin-top: 22px !important;
            }
        }
    }
    &Flex {
        @include flex(flex-start, center, row, wrap);
        @include m767 {
            display: block;
        }
        &.default{
            .c-form {
                &-select {
                    &__option {
                        &--is-focused {
                            color: #333333;
                        }
                        &--is-selected {
                            color: $medium-grey;
                        }
                    }
                    &__menu-list--is-multi {
                        .c-form-select__option {
                            &:after {
                                background-color: $dark-grey;
                            }
                        }
                    }
                }
            }
        }
        .c-button {
            padding: 11px 20px;
            border-radius: 23px;
        }
    }
    &-title {
        position: relative;
        top: -6px;
        flex: 0 1 51.7%;
        color: var(--primary-color);
        .intern_website & {
            color: $intern;
        }
        @include m767 {
            margin-bottom: 30px;
        }
    }
    .c-form {
        &-inputSearchCtn {
            flex: 0 1 43.9%;
            @include m767 {
                margin-bottom: 40px;
            }
        }
        &-selectCtn {
            margin-top: 26px;
            flex: 1 1 0;
            @include m767 {
                margin-top: 20px;
            }
        }
        &-button {
            margin-top: 26px;
            text-align: right;
            @include m767 {
                margin-top: 19px;
                text-align: center;
            }
        }
    }
    &Hidden {
        max-height: 0;
        overflow: hidden;
        transition: max-height .35s ease-in-out, overflow 0s linear .35s;
    }
    &-filters {
        padding-top: 43px;
        padding-bottom: 28px;
        border-top: 3px solid $white;
        .c-search-offersFlex {
            margin-left: -10px;
            margin-right: -10px;
            > * {
                margin-left: 10px;
                margin-right: 10px;

            }
        }
        .c-form-button {
            text-align: center;
        }
    }
    .list-filters {
        .c-search-offersFlex {
            margin-left: -10px;
            margin-right: -10px;
            > * {
                margin-left: 10px;
                margin-right: 10px;
                min-width: calc(33% - 20px);
                max-width: 380px;
            }
        }
    }
    .container {
        .c-button {
            .intern_website & {
                border-color: $intern;
                background-color: $intern;
                @include m1025 {
                    &:hover, &:focus-within {
                        color: $intern;
                        background-color: transparent;
                    }
                }
            }
        }
    }
    .c-button-ctn {
        @include m767 {
            text-align: center;
        }
    }
    &Remove {
        padding: 23px 0;
        background-color: $white;
        .list-filters {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
        }
        .filter-toRemove {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-right: 20px;
            margin-bottom: 10px;
            padding: 8px 17px;
            border-radius: 40px;
            background-color: $light-grey;
            border: 1px solid $light-grey;
            color: $black;
            @include m1025 {
                &:not([disabled]) {
                    transition: all ease-out 200ms;
                    &:hover, &:focus-within {
                        border: 1px solid $pink;
                        background-color: transparent;
                        color: $pink;
                    }
                }
            }
            &[disabled] {
                .filter-toRemove-label {
                    margin-right: 0;
                }
            }
            &-label {
                margin-right: 12px;
                font-size: 16px;
                font-family: $fontText;
                font-weight: 500;
            }
        }
    }
}

.c-search--results {
    .c-search-offersFlex {
        justify-content: center;
        .c-form-inputSearchCtn {
            margin: 0 0 20px 0;
            @include m768 {
                margin: 0 20px 0 0;
            }
        }

    }
    &[data-bg="grey"] {
        margin-top: -45px;
        padding-top: 36px;
        padding-bottom: 36px;
        background-color: $light-grey;
        @include m767 {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }
}