.search__item {
    position: relative;
    display: block;
    padding: 20px 0px;
    border-bottom: 1px solid $medium-grey;
    transition: all ease-out 200ms;
    outline: 0 !important;
    &-type {
        display: block;
        text-transform: uppercase;
        color: var(--secondary-color);
        font-family: $fontTitle;
        font-size: 16px;
        margin-bottom: 10px;
        .intern_website & {
            color: $intern;
        }
    }
    &-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }
    &-title {
        display: block;
        width: calc(100% - 40px);
        font-family: $fontText;
        font-size: 18px;
        color: $black;
        transition: all ease-out 200ms;
    }
    &-icon {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        height: 12px;
        width: 30px;
        color: var(--secondary-color);
        .intern_website & {
            color: $intern;
        }
        &:before {
            order: 2;
            margin-top: 0px;
            margin-left: -4px;
            transition: all ease-out 200ms;
        }
        &:after {
            height: 2px;
            width: 15px;
            transform: translateY(-0.5px);
            background-color: var(--secondary-color);
            content: '';
            transition: all ease-out 200ms;
            .intern_website & {
                color: $intern;
            }
        }
    }
    @include m1025 {
        &:hover, &:focus-within {
            background-color: $light-grey;
            .search__item-icon {
                &:after {
                    width: 25px;
                }
            }
        }
    }
}