.c-testimonies {
    padding-top: 60px;
    padding-bottom: 60px;

    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 35px;
    }

    &-title {
        color: $dark-blue;
        &.c-testimonies-title--mini {
            max-width: 66%;
            @include m1024 {
                max-width: 999px;
            }
        }
    }
    .grid {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(calc(33.33% - 20px), 1fr));
        grid-auto-rows: 20px;
        @include m1024 {
            display: block;
        }
    }
    &-list {
        margin-top: 50px;
        @include m1024 {
            margin-top: 22px;
        }
    }
    &-button {
        text-align: center;
        .c-button {
            @include button($dark-blue);
        }
    }
    .c-push--elevator {
        margin-top: -149px;
        @include m1024 {
            margin-top: 0;
        }
    }
}

.main__content {
    .c-testimonies {
        padding-top: 0;
        padding-bottom: 0;
        &-list {
            margin-top: 38px;
        }
        &-button {
            padding-bottom: 60px;
        }
    }
}