.f-video {
    padding-bottom: 0;
    &[data-bg=grey] {
        .c-quote {
            background-color: $white;
            border-color: $white;
        }
    }

    .c-video {
        margin-left: 44px;
        margin-right: 44px;
        @include m767 {
            margin-left: 20px;
            margin-right: 20px;
        }
        &-button {
            .icon-play {
                font-size: 98px;
                @include m767 {
                    font-size: 51px;
                }
            }
        }
    }

    .c-video + .c-quote {
        padding-top: 128px;
        @include m767 {
            padding-top: 110px;
        }
    }

    .c-testimony {
        padding-bottom: 85px;
        @include m767 {
            padding-bottom: 80px;
        }
    }

    .c-quote {
        padding: 50px 44px 40px;
        @include m767 {
            padding: 40px 20px 35px;
        }
        &--noicon {
            &:after {
                display: none;
            }
        }

        &-author {
            color: var(--secondary-color);
            @include m767 {
                font-size: 16px;
                span {
                    font-size: 16px;
                }
            }
        }

        &-text + .c-quote-author {
            margin-top: 20px;
        }

        &:after {
            right: 136px;
            color: rgba(var(--secondary-color-rgb), .35);
            @include m767 {
                right: 40px;
            }
        }
    }
}