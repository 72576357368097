.f-margin{
    &--petite{
        height: 40px;
    }

    &--moyenne{
        height: 70px;
    }

    &--grande{
        height: 100px;
    }

    &--Gris{
        background-color: $light-grey;
    }

    @include m1024{
        &--petite{
            height: 30px;
        }
    
        &--moyenne{
            height: 50px;
        }
    
        &--grande{
            height: 70px;
        }
    }
}