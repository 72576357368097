.t-offer-list {
    .main__content {
        padding-bottom: 68px;
        border-bottom: 9px solid $light-grey;
    }
    .offers {
        margin-top: 35px;
        @include m767 {
            margin-top: 15px;
        }
        &-button {
            margin-top: 37px;
            text-align: center;
            .c-button {
                @include button(var(--primary-color));
            }
        }
    }
    .c-search-button {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 50px auto;
    }
}