.c-share {
    position: relative;
    display: inline-block;
    &:hover,
    &[data-focus="true"] {
        .c-share-list {
            padding-left: 34px;
            padding-right: 65px;
            visibility: visible;
            width: calc(100% + 50px);
            transition: width .4s ease-in-out, padding .2s ease-in-out .2s;
            i {
                opacity: 1;
                transition: opacity .25s ease-in-out .3s, color .35s ease-in-out;
            }
        }
    }
    &-ctn {
        @include m1024 {
            text-align: right;
        }
    }
    &-button {
        font-weight: 500;
        font-size: 16px;
        min-width: 200px;
        text-align: right;
        outline: none !important;
        .icon-share {
            position: relative;
            z-index: 2;
            vertical-align: middle;
            display: inline-block;
            margin-left: 9px;
            font-size: 20px;
            @include m1024 {
                font-size: 27px;
            }
        }
    }
    &-list {
        box-sizing: border-box;
        @include flex(space-between, center);
        position: absolute;
        overflow: hidden;
        right: -25px;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 100%;
        padding-top: 23px;
        padding-bottom: 22px;
        visibility: hidden;
        border-radius: 27px;
        background-color: $light-grey;
        transition: width .3s ease-in-out .2s, padding .15s ease-in-out .2s, visibility 0s linear .5s;
        a {
            outline: none;
        }
        @include m1025 {
            a:hover, a:focus-visible {
                i {
                    color: $pink;
                }
            }
        }
        i {
            opacity: 0;
            font-size: 16px;
            transition: all .25s ease-in-out;
            &.icon-mail {
                font-size: 13px;
            }
        }
    }
}

[data-bg=grey] {
    .c-share {
        &-list {
            background-color: $white;
        }
    }
}