.bodyContainer {
    .c-job-sectors {
        .swiper-container:not(.swiper-container-multirow-column) .swiper-wrapper .swiper-slide {
            @include m1025 {
                width: 40.7% !important;
                height: auto !important;
                a {
                    width: 100%;
                }
            }
        }
    }
}

.c-job-sectors {
    padding-top: 75px;
    padding-bottom: 65px;

    @include m1024 {
        padding-top: 50px;
        padding-bottom: 55px;
    }
    @include m767 {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    &-slider {
        margin-top: 35px;
        a {
            position: relative;
            overflow: hidden;
            display: block;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            font-family: $fontTitle;
            font-size: 35px;
            text-align: center;
            color: $white;
            @include m1024 {
                font-size: 25px;
            }
            &:before {
                content: '';
                display: block;
                width: 100%;
                padding-bottom: 60%;
                background-color: rgba(var(--secondary-color-rgb), .3);
                transition: all .35s ease-in-out;
            }
            span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: block;
                z-index: 1;
            }
            @include m1025 {
                &:hover, &:focus-within {
                    &:before {
                        background-color: var(--secondary-color);
                    }
                }
            }
        }
    }
    &-button {
        text-align: center;
    }
    .c-button {
        margin-top: -15px;
        @include button(var(--secondary-color));
    }
}

.c-news + .c-job-sectors {
    padding-top: 0;
    @include m1024 {
        padding-top: 0;
    }
    @include m767 {
        padding-top: 0;
    }
}