$fontPath: '../../Assets/fonts/';

@font-face {
    font-family: 'icomoon';
    src: url('#{$fontPath}Icomoon/icomoon.eot?dxi7k1');
    src: url('#{$fontPath}Icomoon/icomoon.eot?dxi7k1#iefix') format('embedded-opentype'),
    url('#{$fontPath}Icomoon/icomoon.ttf?dxi7k1') format('truetype'),
    url('#{$fontPath}Icomoon/icomoon.woff?dxi7k1') format('woff'),
    url('#{$fontPath}Icomoon/icomoon.svg?dxi7k1#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

svg {
    display: block;
    max-width: 100%;
    max-height: 100%;
}

@mixin icomoon() {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    //speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"], [class*=" icon-"] {
    @include icomoon();
}

.icon-alert:before {
    content: "\e900";
}

.icon-arrow-back:before {
    content: "\e901";
    color: var(--primary-color);
}

.icon-arrow-bottom:before {
    content: "\e902";
}

.icon-arrow-left:before {
    content: "\e903";
}

.icon-arrow-right:before {
    content: "\e904";
}

.icon-arrow-top:before {
    content: "\e905";
}

.icon-check:before {
    content: "\e906";
}

.icon-close:before {
    content: "\e907";
}

.icon-contract:before {
    content: "\e908";
    color: $black;
}

.icon-facebook:before {
    content: "\e909";
}

.icon-filter:before {
    content: "\e90a";
}

.icon-glassdoor:before {
    content: "\e90b";
}

.icon-heart:before {
    content: "\e90c";
    color: $watermelon;
}

.icon-heart-full:before {
    content: "\e91c";
    color: $watermelon;
}

.icon-linkedin:before {
    content: "\e90d";
}

.icon-location:before {
    content: "\e90e";
    color: $black;
}

.icon-long-arrow:before {
    content: "\e90f";
}

.icon-mail:before {
    content: "\e910";
}

.icon-play:before {
    content: "\e911";
    color: $white;
}

.icon-plus:before {
    content: "\e912";
    color: $white;
}

.icon-profil:before {
    content: "\e913";
    color: $white;
}

.icon-quote:before {
    content: "\e914";
}

.icon-ref:before {
    content: "\e91d";
}

.icon-reset:before {
    content: "\e915";
    color: $white;
}

.icon-scoring:before {
    content: "\e916";
    color: $black;
}

.icon-search:before {
    content: "\e917";
}

.icon-share:before {
    content: "\e918";
    color: $black;
}

.icon-sound-off:before {
    content: "\e91e";
    color: $white;
}

.icon-sound-on:before {
    content: "\e91f";
    color: $white;
}

.icon-studies:before {
    content: "\e919";
    color: $black;
}

.icon-time:before {
    content: "\e91a";
    color: $black;
}

.icon-twitter:before {
    content: "\e91b";
}

.icon-instagram:before {
    content: "\e920";
}