.c-offer {
    display: block;
    position: relative;
    overflow: hidden;
    margin-top: 20px;
    border-radius: 27px;
    @include m767 {
        margin-left: -10px;
        margin-right: -10px;
        width: calc(100% + 20px);
    }
    &Link {
        display: block;
        padding: 27px 22px;
        border: solid 1px $medium-grey;
        border-radius: 27px;
        transition: border-color .35s ease-in-out;
        @include m767 {
            padding-top: 26px;
            padding-bottom: 26px;
        }
        @include m1025 {
            &:hover, &:focus-within {
                border-color: $pink;
                .c-offerHover {
                    opacity: 1;
                }
                + .c-offer-fav {
                    i:before {
                        color: $white !important;
                    }
                }
            }
        }
    }
    &Hover {
        opacity: 0;
        @include flex(center, center);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $pink;
        transition: opacity .35s ease-in-out;
        .c-button {
            @include button($white);
            color: $pink;
        }
    }
    &-title {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 2px solid var(--primary-color);
        .intern_website & {
            border-color: $intern;
        }
    }
    &-brand {
        font-family: $fontText;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        color: var(--primary-color);
        .intern_website & {
            color: $intern;
        }
        &-img{
            margin-top: 5px;
            img{
                height: auto;
                width: auto;
                max-height: 30px;
            }
        }
    }
    &-brand + .c-offer-infos,  &-brand-img + .c-offer-infos {
        li {
            margin-top: 10px;
        }
    }
    &-infos {
        @include flex(flex-start, center, row, wrap);
        margin-left: -8px;
        margin-right: -8px;
        padding-right: 42px;
        // padding-right: 64px;
        @include m1024 {
            display: block;
        }
        > * {
            margin-left: 8px;
            margin-right: 8px;
        }
        li {
            font-family: $fontText;
            font-size: 16px;
            font-weight: 500;
            @include m767 {
                &:not(:last-child){
                    margin-bottom: 14px;
                }
            }
            i {
                display: inline-block;
                vertical-align: middle;
                margin-right: 9px;
                font-size: 25px;
            }
        }
    }
    &-fav {
        display: block;
        position: absolute;
        right: 22px;
        bottom: 22px;
        font-size: 24px;
        outline: 0 !important;
        i {
            transition: color .35s ease-in-out;
        }
        @include m1025 {
            &:hover, &:focus-within {
                .icon-heart:before {
                    content: "\e91c";
                }
            }
        }
        &.active {
            .icon-heart:before {
                content: "\e91c";
            }
        }
    }
}

.t-offer-details .main__header {
    .c-offer {
        &-infos {
            padding-right: 0;
            @include m1024 {
                //margin-bottom: 55px;
                margin-top: 20px;
            }
            li {
                @include m1024 {
                    &:not(:last-child){
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}