.c-ambassador {
    text-align: left;
    &-title {
        color: $blue !important;
    }
    > img {
        display: block;
        margin-top: 10px;
        max-width: 140px;
        height: auto;
    }
    &-img {
        position: relative;
        &Crop {
            position: relative;
            overflow: hidden;
            width: 193px;
            height: 193px;
            margin: 32px auto 12px;
            border-radius: 50%;
            @include m767 {
                width: 162px;
                height: 162px;
                margin-top: 60px;
            }
        }
        &:before {
            position: absolute;
            top: -15px;
            left: 0;
            content: "\e914";
            font-size: 96px;
            color: rgba($blue, .2);
            @include icomoon();
            @include m767 {
                top: -45px;
            }
        }
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: auto;
            height: 100%;
        }
    }
    &-button {
        margin-top: 20px;
        text-align: center;
    }
    .c-quote {
        &-text, &-text p {
            font-size: 18px;
            @include m767 {
                font-size: 16px;
            }
        }
        &-author {
            color: $blue;
        }
    }
    .c-button {
        @include button($blue);
    }
}