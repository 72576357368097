.c-offers,
.c-testimonies,
.c-filters {
  .c-form {
    &-select {
      .c-form-select__menu-list:not(.c-form-select__menu-list--is-multi) {
        .c-form-select__option {
          padding: 10px 23px;
          &:before,
          &:after {
            display: none;
          }
          &--is-selected {
            background-color: $medium-grey;
            &.c-form-select__option--is-focused {
              background-color: $medium-grey;
            }
          }
        }
      }
    }
  }
}
// .c-modal-body {
.bodyContainer .c-form,
.c-modal-body .c-form,
.c-form {
  .c-form {
    &-select__control,
    &-inputSearchCtn,
    &-input {
      background-color: $white;
      border-color: $medium-grey;
      color: $black;
    }
    &-select {
      &__control {
        @include m1025 {
          &:hover,
          &:focus-within {
            border-color: $medium-grey;
          }
        }
        &--is-focused {
          border-color: $medium-grey;
        }
      }
      &__value-container {
        .c-form-select__single-value,
        .c-form-select__placeholder {
          color: $black;
        }
      }
      &__indicators {
        .c-form-select__dropdown-indicator {
          color: $black;
        }
      }
      &__menu {
        background-color: $white;
        border-left: 1px solid $medium-grey;
        border-right: 1px solid $medium-grey;
        border-bottom: 1px solid $medium-grey;
      }
    }
    &-input,
    &-inputSearchCtn {
      input {
        color: $black;
        background-color: $white;
        @include placeholder {
          color: $black;
        }
      }
      /* Change the white to any color ;) */
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
    }
  }
}

.c-filters[data-custom-color="true"] {
  .c-form {
    &-select__control,
    &-inputSearchCtn,
    &-input {
      color: inherit;
    }
    &-selectCtn {
      &:after {
        color: inherit;
      }
    }
    &-select {
      //color: $dark-blue;
      &__value-container {
        .c-form-select__placeholder {
          color: inherit;
        }
        .c-form-select__single-value {
          color: inherit;
        }
      }
      &__indicators {
        .c-form-select__dropdown-indicator {
          color: inherit;
        }
      }
    }

    &-input,
    &-inputSearchCtn {
      input {
        @include placeholder {
          color: inherit;
        }
      }
    }
    &-inputSearchCtn {
      &:after {
        color: inherit;
      }
    }
  }
}

.c-filters[data-color="blue"] {
  .c-form {
    &-select__control,
    &-inputSearchCtn,
    &-input {
      color: $dark-blue;
    }
    &-selectCtn {
      &:after {
        color: $dark-blue;
      }
    }
    &-select {
      //color: $dark-blue;
      &__value-container {
        .c-form-select__placeholder {
          color: $dark-blue;
        }
        .c-form-select__single-value {
          color: $dark-blue;
        }
      }
      &__indicators {
        .c-form-select__dropdown-indicator {
          color: $dark-blue;
        }
      }
      &__option {
        &--is-focused {
          color: $blue;
        }
        &--is-selected {
          color: $white;
        }
      }
    }

    &-input,
    &-inputSearchCtn {
      input {
        @include placeholder {
          color: $dark-blue;
        }
      }
    }
    &-inputSearchCtn {
      &:after {
        color: $dark-blue;
      }
    }
  }
}

// .c-modal-body {
.bodyContainer .c-form,
.c-modal-body .c-form,
.c-form {
  &[data-bg="grey"] {
    .c-form {
      &-select__control,
      &-input {
        border: 1px solid $medium-grey;
      }
      &-select__control {
        @include m1025 {
          &:hover,
          &:focus-within {
            border-color: $medium-grey;
          }
        }
        &--is-focused {
          border-color: $medium-grey;
        }
      }
      &-select__menu {
        border-left: 1px solid $medium-grey;
        border-right: 1px solid $medium-grey;
        border-bottom: 1px solid $medium-grey;
      }
    }
  }
  &-select__control,
  &-input {
    border-radius: 72px;
    background-color: $light-grey;
    border: 1px solid $light-grey;
    @include formEl();
    color: var(--primary-color);
    &[data-error="true"] {
      border-color: $pink;
    }
  }
  &-select {
    &.uppercase{
      .c-form-select__option{
        text-transform: uppercase;
        font-size: 13px;
      }
    }
    &__control {
      width: 100%;
      padding: 4px 23px 5px;
      box-shadow: none;
      @include m1025 {
        &:hover,
        &:focus-within {
          border-color: $light-grey;
        }
      }
      &--is-focused {
        border-color: $light-grey;
      }
      &--menu-is-open {
        border-top-left-radius: 18px;
        border-top-right-radius: 18px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        svg {
          transform: rotate(180deg);
        }
      }
    }
    &__value-container {
      padding: 0;
      .c-form-select__placeholder {
        width: 100%;
        margin: 0;
        color: var(--primary-color);
        white-space: nowrap;
      }
      .c-form-select__single-value {
        color: var(--primary-color);
      }
    }
    &__indicators {
      .c-form-select__indicator-separator {
        display: none;
      }
      .c-form-select__dropdown-indicator {
        position: relative;
        right: -6px;
        color: var(--primary-color);
        padding: 0;

        svg {
          width: 22px;
          height: 22px;
        }
      }
    }

    &__menu {
      z-index: 9999999;
      overflow: hidden;
      margin: -4px 0 0;
      background-color: $light-grey;
      box-shadow: 2px 2px 1px $white;
      border-left: 1px solid $light-grey;
      border-right: 1px solid $light-grey;
      border-bottom: 1px solid $light-grey;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 18px;
      border-bottom-right-radius: 18px;
    }
    &__menu-list {
      padding: 0;
    }
    &__menu-list--is-multi {
      .c-form-select__option {
        padding: 10px 23px 10px 45px;

        &:before,
        &:after {
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translateY(-50%);
          height: 16px;
          width: 16px;
        }
        &:before {
          content: "";
          border: 1px solid $medium-grey;
        }
        &:after {
          content: "\e906";
          @include icomoon();
          color: $white;
          font-size: 9px;
          background-color: var(--primary-color);
          opacity: 0;
          line-height: 16px;
          text-align: center;
          transition: all ease-out 200ms;
        }
        &--is-selected {
          &:after {
            opacity: 1;
          }
        }
      }
    }
    &__option {
      position: relative;
      padding: 10px 23px 10px 26px;
      font-family: $fontText;
      font-size: 16px;
      font-weight: 500;
      &--is-focused {
        background-color: transparent;
        color: var(--primary-color);
      }
      &--is-selected {
        color: var(--secondary-color);
        background-color: transparent;
        &.c-form-select__option--is-focused {
          background-color: transparent;
        }
      }
    }
  }

  &-input {
    padding: 4px 23px 5px;
    line-height: 1.7;
  }
  &-inputSearchCtn {
    border-radius: 72px;
    position: relative;
    font-size: 19px;
    @include formEl();
    color: var(--primary-color);
    border: 0;
    &.default{
      color:$black;
      &:after{
        color:$dark-grey;
      }
    }
    &[data-error="true"] {
      input {
        border-color: $pink;
      }
    }
    @include m767 {
      padding-top: 9px;
      padding-bottom: 9px;
    }
    &:after {
      position: absolute;
      right: 18px;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      content: "\e917";
      font-size: 23px;
      color: var(--primary-color);
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: "icomoon" !important;
      //speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    input {
      padding: 11px 28px 10px;
      border-radius: 72px;
      background-color: $light-grey;
      border: 1px solid $medium-grey;
      outline: 0;
      &:focus-visible {
        background-color: $white;
      }
    }
  }
  &-checkbox {
    margin-top: 26px;
    position: relative;
    @include flex(flex-start, flex-start, row, nowrap);
    &[data-error="true"] {
      .c-form-checkbox-box {
        border-color: $pink;
      }
    }
    label,
    label a {
      font-family: $fontText;
      font-size: 14px;
      font-weight: 500;
      a {
        position: relative;
        z-index: 2;
        margin: 0;
      }
    }
    input {
      cursor: pointer;
      z-index: 1;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100% !important;
      line-height: normal !important;
      padding: 0;
    }
    input:checked + .c-form-checkbox-box {
      border-color: var(--primary-color);
      background-color: var(--primary-color);
      &:before {
        opacity: 1;
      }
    }
    &-box {
      position: relative;
      top: 4px;
      display: block;
      margin-right: 10px;
      width: 16px;
      height: 16px;
      min-width: 16px;
      border: 1px solid $medium-grey;
      transition: all 0.35s ease-in-out;
      &:before {
        content: "\e906";
        @include icomoon();
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $white;
        font-size: 9px;
        text-align: center;
        vertical-align: middle;
        opacity: 0;
        transition: opacity 0.35s ease-in-out;
      }
    }
  }
  &-recaptcha {
    margin-top: 20px;
    &[data-error="true"] {
      border: 1px solid $pink;
    }
  }
  input {
    width: 100%;
    color: $dark-grey;
    @include placeholder {
      color: var(--primary-color);
    }
  }
  button[type="submit"] {
    display: block;
    margin-top: 26px;
    @include button($pink);
    &[disabled] {
      @include button($dark-grey);
      @include m1025 {
        &:hover,
        &:focus-within {
          background-color: $dark-grey;
          color: $white;
        }
      }
    }
  }
  &.c-search-offers {
    .c-form-select__indicators {
      height: 27px;
      margin: auto;
    }
  }
}

.intern_website {
  .c-form {
    &-select__control,
    &-input {
      color: $intern;
    }
    &-select {
      &__value-container {
        .c-form-select__placeholder {
          color: $intern;
        }
        .c-form-select__single-value {
          color: $intern;
        }
      }
      &__indicators {
        .c-form-select__dropdown-indicator {
          color: $intern;
        }
      }
      &__option {
        &:after {
          background-color: $intern;
        }
        &--is-focused {
          color: $intern;
        }
      }
    }
    &-checkbox {
      input {
        @include placeholder {
          color: $intern;
        }
      }
      input:checked + .c-form-checkbox-box {
        background-color: $intern;
        border-color: $intern;
      }
    }
    &-inputSearchCtn {
      input {
        color: $intern;
        @include placeholder {
          color: $intern;
        }
      }
      &:after {
        color: $intern;
      }
    }
  }
}

.error {
  margin-top: 8px;
  padding-left: 23px;
  padding-right: 23px;
  font-family: $fontText;
  font-size: 11px;
  font-weight: 700;
  color: $pink;
  word-break: break-word;
  &--big {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 8px 23px 9px;
    background-color: rgba($dark-grey, 0.8);
    border-radius: 26px;
    font-size: 14px;
    color: $white;
  }
}
