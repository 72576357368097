.c-faq {
    &:not(:first-child) {
        margin-top: 80px;
        @include m767 {
            margin-top: 35px;
        }
    }

    &-list {
        &:not(:first-child) {
            margin-top: 30px;
        }
    }
    &-item {
        &:not(:last-child) {
            .c-faq-contentInner {
                margin-bottom: 50px;
            }
        }
        &.opened {
            .c-faq-content {
                max-height: 700px;
                transition: max-height .35s ease-in-out .2s;
            }
            .icon-arrow-bottom {
                &:before {
                    content: "\e905";
                }
            }
        }
    }
    &-trigger {
        @include flex(space-between, flex-start);
        width: 100%;
        padding-bottom: 10px;
        padding-right: 25px;
        margin-bottom: 30px;
        border-bottom: 1px solid $dark-grey;
        font-family: $fontText;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        text-align: left;
    }
    &-content {
        overflow: hidden;
        max-height: 0;
        transition: max-height .3s ease-in-out;
    }
    .icon-arrow-bottom {
        position: relative;
        top: 7px;
        right: -25px;
        font-size: 7px;
    }
}