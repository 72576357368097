.c-cards {
    margin-top: 55px;
    @include m991 {
        margin-top: 30px;
    }

    p {
        margin-top: 10px;
    }
    .c-video-button {
        .icon-play {
            font-size: 86px;
            @include m1024 {
                font-size: 59px;
            }
        }
    }
    &__list {
        @include flex(flex-start, initial, row, wrap);
        gap: 12px;
        @include m991 {
            display: block;
        }
        //&--filter {
        //    .c-cards__item {
        //        color: $white;
        //    }
        //}
        &[data-dark="true"] {
            color: $black;
            .title-40, .icon-play:before {
                color: $black;
            }
        }
        &[data-dark="false"] {
            color: $white;
            .title-40, .icon-play:before {
                color: $white;
            }
        }
    }
    &__item,
    .react-player__preview {
        position: relative;
    }
    /*&__item,
    .react-player__preview,
    .c-video .video_cookie {
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(#655679, 0.7);
      }
    }*/
    &__item {
        flex: 1 0 33%;
        @include flex(center, center, column);
        min-height: calc(0.5625 * (1180px / 2 + 44px));
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        text-align: center;

        @include m991 {
            margin: 11px;
            min-height: calc(0.5625 * (100vw - 80px - 11px));
        }
        @include m767 {
            min-height: calc(0.5625 * (100vw - 40px - 11px));
        }
        > * {
            position: relative;
            z-index: 1;
            width: 100%;
        }
        .c-video {
            height: 100%;
        }
        &-filter {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: .7;
        }
    }
}
