.c-offer-sticky {
    z-index: 19;
    position: fixed;
    top: 185px;
    right: 0;
    text-align: right;
    @include m991 {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        top: auto;
        bottom: 0;
        right: 50%;
        transform: translateX(50%);
        padding: 6px 11px;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        background-color: $pink;
    }
    &-button {
        padding: 9px 10px 9px 15px;
        @include flex(flex-start, center);
        max-width: 178px;
        border-radius: 27px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        @include button($pink);
        border-right: none;
        font-size: 14px;
        text-align: left;
        @include m991 {
            max-width: 999px;
            padding: 0 47px 0 6px;
            background-color: transparent;
            border-color: transparent;
            // font-size: 15px;
            white-space: nowrap;
            padding-right: 15px;
            font-size: 13px;
            &:first-child {
                flex: 1;
            }
        }
        .icon-long-arrow {
            margin-right: 10px;
            font-size: 12px;
        }
        @include m1025 {
            &:hover, &:focus-within {
                background-color: $white;
                color:$pink;
            }
        }
    }
    &-fav {
        display: block;
        margin-right: 12px;
        margin-left: auto;
        margin-top: 18px;
        font-size: 24px;
        transition: font-size .3s ease-in-out;
        @include m991 {
            min-width: 32px;
            width: 32px;
            height: 32px;
            margin: 0;
            background-color: $white;
            border-radius: 50%;
            font-size: 18px;
            line-height: 32px;
            text-align: center;
            i {
                position: relative;
                top: 2px;
                left: 1px;
            }
        }
        @include m1025 {
            &:hover, &:focus-within {
                font-size: 26px;
            }
        }
        &.active {
            .icon-heart:before {
                content: "\e91c";
            }
        }
    }
}