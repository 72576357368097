.t-home, .t-404 {
    .header {
        &__size {
            transition: min-height .35s ease-in-out;
        }
    }
}

.header {
    z-index: 500;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(0);
    transition: transform .35s ease-in-out .1s;
    &[data-sticky="true"] {
        transform: translateY(calc(-1 * (100% + 40px)));
        transition: transform .25s ease-in-out;
        &[data-mini="true"] {
            transform: translateY(0);
            transition: transform .35s ease-in-out .15s;
        }
    }
    &[data-mini-remove="true"] {
        transition: transform .3s ease-in-out;
        transform: translateY(calc(-1 * (100% + 40px)));
        &[data-mini="true"] {

        }
    }
    &[data-mini="true"] {
        position: fixed;
        @include m1200 {
            min-height: 56px;
        }
        &[data-wave-invert="true"],
        &[data-menu-opened="true"] {
            .header {
                &__logo {
                    &:before {
                        @include m1199 {
                            width: 340px;
                            background-image: url('#{$imgPath}logo-wave-m.svg');
                        }
                        @include m479 {
                            width: 320px;
                            background-position: right;
                            background-size: cover;
                        }
                    }
                    a, > div {
                        top: 10px;
                        @include m1199 {
                            top: -5px;
                        }
                    }
                }
            }
        }
        &[data-menu-visible="false"] {
            .menu__submenu {
                display: none;
            }

        }
        &[data-menu-visible="true"] {
            .menu__submenu {
                display: block;
            }
            .header {
                &Main {
                    &Flex {
                        &Right {
                            opacity: 1;
                            visibility: visible;
                            margin-left: 0;
                            transition: margin-left .35s ease-in-out, opacity .35s ease-in-out;
                        }
                    }
                }
                &__toggleMenu {
                    span.text {
                        display: none;
                    }
                }
            }
        }
        &.header {
            .menu__submenu {
                top: calc(100% - 4px);
            }
        }
        .header {

            &Main {
                z-index: unset;
                position: absolute;
                width: 100%;
                bottom: 0;
                left: 0;
                //padding-top: 21px;
                //padding-bottom: 6px;
                @include m1200 {
                    min-height: 56px;
                }

                @include m1199 {
                    position: relative;
                    padding-top: 25px;
                    padding-bottom: 10px;
                    border-bottom: none;
                }
                &Flex {
                    &Right {
                        margin-left: 50px;
                        //margin-top: -11px;
                        padding-top: 0;
                        margin-right: 280px;
                        opacity: 0;
                        visibility: hidden;
                        transition: opacity .3s ease-in-out, margin-left .3s ease-in-out .15s, visibility 0s linear .5s;
                        @include m1199 {
                            margin-left: 0;
                            margin-top: -10px;
                            margin-right: 0;
                            opacity: 1;
                            visibility: visible;
                            transition: none;
                        }
                    }
                }
            }
            &Bottom {
                // z-index: 1;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                padding-top: 0;
                margin-bottom: 0;
                background-color: transparent;
                border-bottom-width: 3px;
            }
            &__logo {
                &:before {
                    width: 299px;
                    background-position: bottom 2px left;
                    background-image: url('#{$imgPath}logo-wave-mini.svg');
                    @include m1199 {
                        //width: 340px;
                        background-position: bottom left;
                        transition: width .35s ease-in-out;
                        //background-image: url('#{$imgPath}logo-wave-m.svg');
                    }
                    @include m479 {
                        //width: 320px;
                        width: 280px;
                        ////background-size: auto calc(100% - 18px);
                        //background-size: contain;
                        //background-position: bottom -1px right;
                        //background-position: right;
                        //background-size: cover;

                    }
                }
                &:after {
                    /*   position: absolute;
                       bottom: 1px;
                       left: 0;
                       content: '';
                       display: block;
                       width: 100%;
                       height: 2px;
                       background: rgb(255, 255, 255);
                       background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 24%, rgba(255, 255, 255, 0) 30%);
                       @include m1199 {
                           bottom: -1px;
                           background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 35%);
                       }*/
                }
                a, > div {
                    top: 10px;
                    // top: -5px;
                    left: -3px;
                    transition: top .35s ease-in-out;
                    @include m1199 {
                        top: 3px;
                    }
                }
                img {
                    max-width: 175px;
                }
            }
            &__menu {
                > li {
                    > a, > span {
                        font-size: 16px;
                        @include m1025 {
                            padding: 20px;
                            &:after {
                                top: calc(100% - 1px);
                            }
                        }
                        @media screen and (min-width: 1360px) {
                            padding: 20px 24px 20px;
                        }
                    }
                }
            }
            &__search {
                margin-top: -2px;
                margin-left: 40px;
                @media screen and (min-width: 1360px) {
                    margin-left: 56px;
                }
                .icon-search {
                    font-size: 17px;
                }
            }
            &__language {
                margin-top: 0;
                a, button {
                    font-size: 16px;
                }
            }
            &__profile {
                .icon-profil {
                    font-size: 20px;
                }
            }
            &__toggleElevator {
                width: 176px;
                height: 38px;
                top: calc(100% - 8px);
                &[data-custom-color="false"] {
                    width: 179px;
                    background-image: url('#{$imgPath}menu-wave-mini.svg');
                }
                .intern_website & {
                    background-image: url('#{$imgPath}menu-wave-mini-intern.svg');
                }
                @include m1199 {
                    display: none;
                }
                &:after {
                    top: calc(50% - 8px);
                    width: 15px;
                    height: 15px;
                    border-width: 3px;
                }
            }
            &__toggleMenu {
                z-index: 8;
                opacity: 1;
                visibility: visible;
                transform: translateX(0);
                transition: opacity .3s ease-in-out, transform .3s ease-in-out, text-indent .35s ease-in-out;
                @include m1199 {
                    transition: none;
                }
            }
            &__offerLink {
                margin-left: 43px;
                @include m1199 {
                    display: none;
                }
                &:before {
                    width: 261px;
                    height: 37px;
                }
                &-item {
                    top: -2px;
                    padding-right: 34px;
                    font-size: 15px;
                }
            }
        }
    }
    &Main {
        z-index: 7;
        position: relative;
        //padding-top: 20px;
        //padding-bottom: 21px;
        background-color: var(--primary-color);
        transition: all .35s ease-in-out;

        &[data-custom-header="true"] {
            background-color: transparent;
        }

        &[data-custom-header="false"] {
            .intern_website & {
                background-color: $intern;
            }
        }

        @include m1199 {
            border-bottom: 3px solid $white;
        }
        &Flex {
            @include flex(space-between, center);
            &Right {
                // padding-top: 4px;
                &[data-searchisopen="true"] .header__menu {
                    opacity: 0;
                    visibility: hidden;
                }
            }
            &Right, &RightUser {
                @include flex(flex-end, center);
                transition: opacity .3s ease-in-out;
            }
        }
    }
    &Bottom {
        position: relative;
        padding-top: 22px;
        margin-bottom: 38px;
        background-color: var(--primary-color);
        border-bottom: 4px solid $white;
        transition: all .35s ease-in-out;
        &[data-custom-header="false"] {
            .intern_website & {
                background-color: $intern;
            }
        }
        &[data-custom-header="true"] {
            background-color: transparent;
            margin-top: -50px;
        }
        &Flex {
            @include flex(flex-end, center);
        }
    }
    &__logo {
        &:before {
            z-index: 7;
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 540px;
            height: 100%;
            background-image: url('#{$imgPath}logo-wave.svg');
            background-position: top left;
            background-repeat: no-repeat;
            background-size: 100% auto;
        }
        a, > div {
            position: relative;
            z-index: 7;
            display: block;
        }
        img {
            max-width: 350px;
            height: auto;
            transition: all .35s ease-in-out;
        }
    }
    &__menu {
        margin-left: 30px;
        @include flex(flex-start, center);
        > li {
            //margin-left: 24px;
            //margin-right: 24px;
            background-color: var(--primary-color);

            &[data-custom-header="true"] {
                background-color: transparent;
            }
            &[data-custom-header="false"] {
                .intern_website & {
                    background-color: $intern;
                }
            }

            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
            &.has-children {
                @include m1025 {
                    &:hover, &[data-focus="true"] {
                        > a, > span {
                            /* &:before {
                                 opacity: 1;
                                 visibility: visible;
                                 transition: opacity .3s ease-in-out;
                             }*/
                            &[data-custom-header="false"] {
                                &:after {
                                    overflow: visible;
                                    max-height: 22px;
                                    transform: translate(-50%, 0);
                                    transition: transform .35s ease-in-out;
                                }
                            }
                        }
                        > .menu__submenu {
                            z-index: 6;
                            opacity: 1;
                            visibility: visible;
                            max-height: 999px;
                            transition: max-height .8s ease-in-out;
                            .menu__submenuInner {
                                max-height: 999px;
                                opacity: 1;
                                transition: opacity .4s ease-in-out .2s;
                                > * {
                                    visibility: visible;
                                }
                            }
                        }
                        .menu__submenu-container {
                            display: block !important;
                        }
                    }
                }
            }
            > a, > span {
                cursor: pointer;
                z-index: 7;
                display: block;
                position: relative;
                font-size: 18px;
                font-weight: 500;
                color: $white;
                text-transform: uppercase;
                transition: color .3s ease-in-out;
                @include m1025 {
                    padding: 36px 24px 27px;
                    &:after {
                        z-index: 7;
                        overflow: hidden;
                        content: '';
                        display: block;
                        width: 169px;
                        height: 22px;
                        max-height: 0;
                        position: absolute;
                        top: calc(100% + 3px);
                        left: 50%;
                        transform: translate(-50%, -100%);
                        background-image: url('#{$imgPath}menu-item-wave.svg');
                        background-position: top center;
                        background-repeat: no-repeat;
                        background-size: 100% auto;
                        transition: transform .35s ease-in-out, max-height 0s ease-in-out .35s, overflow 0s ease-in-out .35s;
                        .intern_website & {
                            background-image: url('#{$imgPath}menu-item-wave-intern.svg');
                        }
                    }
                }
                @include m1025 {
                    &:hover, &:focus-within {
                        color: rgba($white, .7);
                    }
                }
            }
        }
    }
    .menu__submenu {
        opacity: 0;
        overflow: hidden;
        box-sizing: border-box;
        z-index: 5;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        border: 9px solid var(--primary-color);
        background-color: $white;
        .intern_website & {
            border-color: $intern;
        }
        @include m1025 {
            max-height: 0;
            visibility: hidden;
            transition: max-height 0s ease-in-out .4s, visibility 0s linear .40s, opacity .35s ease-in-out;
        }
        &:before {
            content: '';
            display: block;
            width: 60px;
            height: 15px;
            position: absolute;
            bottom: 100%;
            left: 50%;
            background-color: var(--primary-color);
            transform: translateX(-50%);
            .intern_website & {
                background-color: $intern;
            }
        }
        &Inner {
            @include flex();
            @include m1025 {
                overflow: hidden;
                opacity: 0;
                max-height: 0;
                transition: max-height 0s ease-in-out .6s, opacity .2s ease-in-out;
                > * {
                    visibility: hidden;
                }
            }
        }
        &-parent {
            display: none;
        }
        &-intro {
            flex: 0 1 calc(365 / 1366 * 100%);
            max-width: 365px;
            padding-top: 35px;
            padding-bottom: 35px;
            background-color: var(--primary-color);
            .intern_website & {
                background-color: $intern;
            }
            .intro__text {
                margin-left: 35px;
                margin-right: 35px;
                font-size: 16px;
                line-height: 1.38;
                color: $white;
            }
            .intro__logos {
                margin-top: 30px;
                li + li {
                    margin-top: 32px;
                }
                a {
                    display: block;
                    transition: opacity .3s ease-in-out;
                    @include m1025 {
                        &:hover, &:focus-within {
                            opacity: 0.8;
                        }
                    }
                }
                img {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    max-width: 157px;
                    max-height: 39px;
                }
            }
        }
        &-parent + .menu__submenu-container {
            // display: none;
            padding: 46px 92px 50px;
        }
        &-container {
            flex: 1;
            padding: 32px 60px;
            background-color: $white;
            > ul {
                @include flex();
                @include m1200 {
                    justify-content: center;
                    margin-left: -15px;
                    margin-right: -15px;
                    &.maxWidth {
                        > li {
                            max-width: 25%;
                        }
                    }
                }
                > li {
                    flex: 1 1 0;
                    @include m1200 {
                        padding-left: 15px;
                        padding-right: 15px;

                    }
                    &.column {
                        flex-grow: 2;
                        > ul {
                            @include m1200 {
                                column-count: 2;
                                column-gap: 20px;
                            }
                        }
                    }
                    > span, > a {
                        display: block;

                        font-size: 18px;
                        font-weight: 600;
                        line-height: 1.22;
                        color: var(--primary-color);
                        text-transform: uppercase;
                        .intern_website & {
                            color: $intern;
                        }
                    }
                    > a {
                        @include flex(flex-start, flex-start);
                        position: relative;
                        left: -30px;
                        transition: all .3s ease-in-out;
                        &:before {
                            position: relative;
                            top: 7px;
                            content: "\e90f";
                            display: block;
                            width: 16px;
                            min-width: 16px;
                            margin-right: 14px;
                            color: transparent;
                            font-size: 11px;
                            @include icomoon();
                            line-height: .5;
                            transition: all .3s ease-in-out;
                        }
                        @include m1025 {
                            &:hover {
                                color: var(--secondary-color);
                                left: 0;

                                &[data-custom-header="true"] {
                                    color: inherit;
                                }

                                &:before {
                                    color: var(--secondary-color);

                                    &[data-custom-header="true"] {
                                        color: inherit;
                                    }
                                }
                            }
                        }
                    }
                    > ul {
                        margin-top: 15px;

                        > li {
                            @include flex(flex-start, flex-start);
                            & + li {
                                margin-top: 15px;
                            }
                            &:before {
                                position: relative;
                                top: 10px;
                                content: "\e90f";
                                display: block;
                                width: 22px;
                                min-width: 22px;
                                height: 1.5px;
                                margin-right: 10px;
                                background-color: var(--primary-color);
                                transition: color .35s ease-in-out, transform .35s ease-in-out, background-color .35s ease-in-out .1s;
                                color: transparent;

                                font-family: 'icomoon' !important;
                                font-style: normal;
                                font-weight: normal;
                                font-variant: normal;
                                text-transform: none;
                                line-height: .5;

                                /* Better Font Rendering =========== */
                                -webkit-font-smoothing: antialiased;
                                -moz-osx-font-smoothing: grayscale;
                                &[data-custom-header="false"] {
                                    .intern_website & {
                                        background-color: $intern;
                                    }
                                }
                                &[data-custom-header="true"] {
                                    background-color: inherit;
                                }
                            }
                            > a {
                                display: block;

                                font-size: 15px;
                                font-weight: 500;
                                line-height: 1.47;
                                color: $black;
                                transition: color .35s ease-in-out;
                            }
                            @include m1025 {
                                &:hover {
                                    &:before {
                                        color: var(--primary-color);
                                        background-color: transparent;
                                        transform: translateX(5px);
                                        transition: color .25s ease-in-out .1s, transform .35s ease-in-out, background-color .35s ease-in-out;
                                        &[data-custom-header="false"] {
                                            .intern_website & {
                                                color: $intern;
                                            }
                                        }
                                        &[data-custom-header="true"] {
                                            color: inherit;
                                        }
                                    }
                                    > a {
                                        color: var(--primary-color);
                                        &[data-custom-header="false"] {
                                            .intern_website & {
                                                color: $intern;
                                            }
                                        }
                                        &[data-custom-header="true"] {
                                            color: inherit;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &__search {
        margin-top: 7px;
        position: relative;
        margin-left: 56px;
        color: $white;
        z-index: 3;
        &-wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-end;
            width: 27px;
            transition: all ease-out 200ms;
        }
        input {
            display: inline-block;
            width: 230px;
            min-width: 100%;
            padding: 9px 0px;
            border-bottom: 1px solid $white;
            font-family: $fontText;
            font-size: 16px;
            color: $white;
            opacity: 0;
            transition: all ease-out 200ms;
            outline: 0;
            &::placeholder {
                color: $white;
            }
        }
        .icon-search {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: 20px;
            transition: font-size .35s ease-in-out;
            @include m1024 {
                top: 20px;
            }
        }
        .autocomp__list {
            transition: all ease-out 200ms;
            background-color: var(--primary-color);
            max-height: 0;
            overflow: hidden;
            .intern_website & {
                background-color: $intern;
            }
            @include m1025 {
                position: absolute;
                right: 0;
                left: 0;
            }
        }
        .autocomp__item {
            display: block;
            width: 100%;
            padding: 5px 0;
            font-size: 16px;
            &:first-child {
                margin-top: 10px;
            }
            &:last-child {
                margin-bottom: 10px;
            }
            @include m1025 {
                padding: 10px 10px;
                transition: all ease-out 200ms;
                &:not(.autocomp__item--noResults) {
                    &:hover, &:focus-within {
                        background-color: rgba(255, 255, 255, .25);
                    }
                }
            }
            &--noResults {
                font-style: italic;
            }
        }
        &[data-opened="true"] {
            .header__search-wrapper {
                width: 270px;
                max-width: 100%;
                @include m768-1024 {
                    width: 100%;
                }
            }
            input {
                opacity: 1;
                padding: 9px 25px 9px 0px;
                @include m1025 {
                    padding: 9px 25px 9px 5px;
                }
            }
            .autocomp__list {
                max-height: 300px;
                transition-delay: 200ms;
            }
        }
    }
    &__language {
        position: relative;
        z-index: 1;
        margin-left: 50px;
        margin-top: 9px;
        a, button {
            display: block;

            font-size: 18px;
            font-weight: 500;
            color: $white;
            text-transform: uppercase;
            transition: opacity .3s ease-in-out, font-size .35s ease-in-out;
            @include m1025 {
                &:hover, &:focus-within {
                    opacity: 0.7;
                }
            }
        }
    }
    &__profile {
        //margin-left: 36px;
        a {
            display: block;
            transition: transform .3s ease-in-out;
            color: $white;
            @include m1025 {
                &:hover, &:focus-within {
                    transform: scale(1.1);
                }
            }
        }
        .icon-profil {
            color: $white;
            font-size: 26px;
            transition: font-size .35s ease-in-out;
        }
    }
    &__toggleElevator {
        z-index: 2;
        position: absolute;
        top: calc(100% - 11px);
        left: 50%;
        width: 234px;
        height: 50px;
        transform: translateX(-50%);
        background-image: url('#{$imgPath}menu-wave.svg');
        background-position: top center;
        background-repeat: no-repeat;
        background-size: 100% auto;
        transition: all .35s ease-in-out;
        .intern_website & {
            background-image: url('#{$imgPath}menu-wave-intern.svg');
        }
        @include m1200 {
            &:hover {
                &:before {
                    width: 100vw;
                    height: 600px;
                }
            }
            &:before {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                opacity: 0;
            }
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: calc(50% - 6px);
            left: 50%;
            transform: translate(-50%, -50%);
            width: 19px;
            height: 19px;
            border-radius: 50%;
            border: 4px solid $white;
            transition: all .35s ease-in-out;
        }
    }
    &__toggleMenu {
        z-index: 4;
        opacity: 0;
        visibility: hidden;
        transform: translateX(30px);
        transition: opacity .3s ease-in-out, transform .3s ease-in-out, visibility 0s linear .3s, text-indent .35s ease-in-out;
        @include flex(flex-start, center);
        position: relative;
        top: -14px;

        font-size: 16px;
        font-weight: 500;
        line-height: 1.47;
        color: $white;
        text-transform: uppercase;
        @include m1199 {
            top: 0;
        }
        span.text {
            transition: opacity .3s ease-in-out;
            @include m479 {
                display: none;
            }
        }
        span:not(.text) {
            position: relative;
            width: 27px;
            height: 8px;
            margin-left: 8px;
            &:before, &:after {
                position: absolute;
                left: 0;
                top: 0;
                content: '';
                display: block;
                width: 100%;
                height: 2px;
                background-color: $white;
                transition: width .3s ease-in-out;
            }
            &:after {
                top: auto;
                bottom: 0;
                width: 16px;
            }
        }
        @include m1025 {
            &:hover, &:focus-within {
                span:not(.text) {
                    &:before {
                        width: 16px;
                    }
                    &:after {
                        width: 100%;
                    }
                }
            }
        }
    }
    &__offerLink {
        z-index: 3;
        position: relative;
        @include flex(flex-start, center);
        margin-left: 55px;
        transition: margin-left .3s ease-in-out;
        &:before {
            z-index: 0;
            position: absolute;
            right: -33px;
            bottom: -2px;
            content: '';
            width: 311px;
            height: 45px;
            background-image: url('#{$imgPath}offer-link.svg');
            background-position: bottom right;
            background-repeat: no-repeat;
            background-size: 100% auto;
            transition: left .35s ease-in-out;
        }
        &-item {
            display: block;
            position: relative;
            top: -3px;
            z-index: 1;
            padding-right: 60px;
            font-size: 17px;
            font-weight: 600;
            line-height: 1.47;
            color: $pink;
            text-transform: uppercase;
            transition: all .35s ease-in-out;
            outline: 0 !important;
            @include m1025 {
                &:hover, &:focus-within {
                    color: var(--primary-color);
                    .intern_website & {
                        color: $intern;
                    }
                    i {
                        transform: translateX(6px);
                        &:after {
                            left: 0;
                        }
                    }
                }
            }
        }
        i {
            position: relative;
            display: inline-block;
            margin-left: 12px;
            font-size: 14px;
            transition: transform .35s ease-in-out;
            &:after {
                position: absolute;
                top: 0;
                left: -6px;
                content: '';
                display: block;
                width: 6px;
                height: 12px;
                background-color: transparent;
                transition: left .35s ease-in-out;
            }
        }
    }
}

// Mobile
.header {
    &Bottom--mobile {
        background-color: $white;
        padding-top: 14px;
        margin-bottom: 10px;
        .intern_website & {
            background-color: $white;
        }
        .header__toggleElevator {
            z-index: 10;
            display: block !important;
            width: 179px;
            height: 38px;
            top: -8px;
            left: 0;
            margin-left: -20px;
            transform: none;
            background-image: url('#{$imgPath}menu-wave-mini.svg');
            .intern_website & {
                background-image: url('#{$imgPath}menu-wave-mini-intern.svg');
            }
            @include m1199 {
                display: none;
            }
            &:after {
                top: calc(50% - 8px);
                width: 15px;
                height: 15px;
                border-width: 3px;
            }
        }
        .header__offerLink {
            z-index: 4;
            display: block;
            margin-left: 43px;
            &:before {
                display: none;
            }
            &-item {
                top: -2px;
                padding-right: 34px;
                font-size: 15px;
            }
        }
    }
    &[data-menu-opened="true"] {
        .header__toggleMenu {
            span:not(.text) {
                &:before, &:after {
                    width: 80%;
                    transform-origin: 33.33%;
                    transition: width .3s ease-in-out, transform .3s ease-in-out;
                }
                &:before {
                    transform: rotate(45deg);
                }
                &:after {
                    transform: rotate(-45deg);
                }
            }
        }
        .headerMobileMenu {
            max-height: calc(100vh - 56px);
            overflow-x: hidden;
            overflow-y: auto;
            visibility: visible;
            transition: max-height .5s ease-in-out;
            > * {
                opacity: 1;
                transition: opacity .35s ease-in-out .1s;
            }
        }
    }
    .headerMobileMenu {
        position: relative;
        max-width: 100%;
        @include flex(initial, initial, column);
        background-color: var(--primary-color);
        height: calc(100vh - 56px);
        max-height: 0;
        overflow: hidden;
        visibility: hidden;
        transition: max-height .3s ease-out, overflow 0s linear .3s, visibility 0s linear .3s;
        .intern_website & {
            background-color: $intern;
        }
        > * {
            opacity: 0;
            transition: opacity .3s ease-out;
        }
        > .container {
            flex: 1;
            @include flex(space-between, initial, column);
        }
        .header__menu > li > a, .header__menu > li > span{
            padding: 0;
        }
        &Header {
            padding-top: 25px;
        }
        &Main {
            padding: 30px 25px;
        }
        &Bottom {
            padding-bottom: 50px;
            background-color: $white;
            &Flex {
                padding-top: 10px;
                padding-bottom: 10px;
                @include flex(space-between, flex-start);
            }
        }

        .header {
            &__menu {
                display: block;
                margin-left: 0;
                > li {
                    margin-left: 0;
                    margin-right: 0;
                    & + li {
                        margin-top: 24px;
                    }
                    > a, > span {
                        font-size: 20px;
                    }
                }
            }
            &__search {
                width: 21px;
                margin-top: 14px;
                margin-left: 0;
                justify-content: flex-end;
                &[data-opened="true"] {
                    width: 100%;
                    max-width: 300px;
                }
            }
            &__language {
                a, button {
                    font-size: 18px;
                }
            }
            &__profile {
                margin-left: 20px;
                padding-right: 6px;
                .icon-profil {
                    font-size: 21px;
                }
            }
            &__toggleElevator {
                display: block;
                position: relative;
                left: 0;
                top: 0;
                margin-top: -17px;
                margin-left: -20px;
                transform: none;
                width: 165px;
                height: 36px;
            }
            &__offerLink {
                z-index: 4;
                display: block;
                margin-left: 0;
                &:before {
                    display: none;
                }
                &-item {
                    padding-right: 0;
                }
            }
        }

        .menu__submenu {
            z-index: 8;
            top: 0 !important;
            left: 100%;
            height: 100%;
            max-height: calc(100vh - 56px);
            overflow-x: hidden;
            overflow-y: auto;
            transition: left .35s ease-in-out;
            &.menu__submenu--opened {
                left: 0;
                opacity: 1;
            }
            &Inner {
                display: block;
            }
            &-parent + .menu__submenu-container {
                padding: 35px 22px;
            }
            &-parent {
                cursor: pointer;
                padding: 15px 8px;
                @include flex(flex-start, center);
                background-color: var(--primary-color);
                font-family: $fontText;
                font-size: 18px;
                font-weight: 500;
                color: $white;
                text-transform: uppercase;
                .intern_website & {
                    background-color: $intern;
                }
                i {
                    margin-right: 15px;
                }
            }
            &-container {
                > ul {
                    display: block;
                    > li {
                        & + li {
                            margin-top: 40px;
                        }
                    }
                }
            }
        }

    }
}