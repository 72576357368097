body {
  font-family: $fontText;
}

.bodyContainer {
  position: relative;
  max-width: 100vw;
  overflow-x: hidden;
}

.main {
  @include m1200 {
    min-height: calc(100vh - (56px + 261px));
  }
}

.container {
  box-sizing: content-box;
  width: calc(100% - 80px);
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
  @include m767 {
    width: calc(100% - 40px);
    padding-left: 20px;
    padding-right: 20px;
  }
  &--menu {
    max-width: 1090px;
  }
  &--maxi {
    width: calc(100% - 64px);
    max-width: 99999px;
    padding-left: 32px;
    padding-right: 32px;
    @include m1279 {
      width: calc(100% - 40px);
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  &--medium {
    max-width: 980px;
  }
  &--mini {
    max-width: 780px;
  }
}

.hidden {
  display: none;
}

//.header--mini + .main {
//    margin-top: 56px;
//}

p,
.paragraph {
  @include text();
}

.title-130 {
  font-family: $fontTitle;
  font-size: 130px;
}

.title-50 {
  @include h2-title();
  color: var(--secondary-color);
  @include m1024 {
    font-size: 30px;
  }
  span {
    display: block;
    font-weight: 300;
    font-size: 40px;
  }
  &.title-50--alt {
    color: $white;
    font-family: $fontText;
    font-size: 55px;
    font-weight: 700;
    @include m1024 {
      font-size: 30px;
    }
    span {
      font-weight: 300;
      font-size: 35px;
      line-height: 1;
      @include m1024 {
        font-size: 20px;
      }
    }
  }
}

h1.title-50 {
  @include m1024 {
    font-size: 40px;
  }
}

.title-40 {
  @include h3-title();
  color: var(--secondary-color);
  @include m1024 {
    font-size: 30px;
  }
}

.title-30 {
  @include h2-title();
  font-size: 30px;
  color: var(--secondary-color);
  @include m1024 {
    font-size: 25px;
  }
  &--alt {
    font-family: $fontText;
    font-weight: 500;
  }
  strong {
    font-weight: 700 !important;
    color: $black;
  }
  &:not(.no-text) {
    .icon {
      margin-right: 15px;
    }
  }
}

.title-25 {
  font-family: $fontTitle;
  font-size: 25px;
  line-height: 1.16;
  color: var(--secondary-color);
}

ul.buttons-list {
  margin-top: 35px;
  margin-bottom: 80px;
  @include m1024 {
    margin-bottom: 60px;
  }
  .c-button {
    position: relative;
    display: block;
    padding: 13px 28px;
    font-family: $fontTitle;
    @include button($white);
    border-radius: 16px;
    border-color: $medium-grey;
    font-size: 24px;
    text-align: left;
    color: var(--secondary-color);
    transition: all 0.35s ease-in-out !important;
    &:after {
      overflow: hidden;
      position: absolute;
      top: 50%;
      right: 28px;
      transform: translateY(-50%);
      width: 0;
      font-size: 12px;
      display: block;
      content: "\e90f";
      @include icomoon();
      color: $white;
      transition: all 0.35s ease-in-out;
    }
    @include m1025 {
      &:hover,
      &:focus-within {
        color: $white !important;
        background-color: var(--secondary-color);
        border-color: var(--secondary-color);
        &:after {
          width: 23px;
        }
      }
    }
  }
}

.main {
  &__header {
    .t-home &{
      margin-top: -38px;
    }
    &[data-color="blue"] {
      .main__header-intro {
        .title-50 {
          color: $dark-blue;
        }
      }
    }
    &[data-color="purple"] {
      .main__header-intro {
        .title-50 {
          color: var(--primary-color);
          .intern_website & {
            color: $intern;
          }
        }
      }
    }
    &-media {
      position: relative;
      overflow: hidden;
      &:before {
        content: "";
        display: block;
        width: 100%;
        // padding-bottom: 34.62%;
      }
      img {
        display: block;
        width: 100%;
        // width: auto;
        // height: 100%;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
      }
      &.c-video {
        &:before {
          padding-bottom: 41.72%;
          @include m1024 {
            padding-bottom: 47.5%;
          }
        }
        .c-video-iframe {
          .container {
            position: relative;
            height: 100%;
          }
          .c-video-button {
            z-index: 5 !important;
            position: absolute;
            top: auto !important;
            bottom: 70px !important;
            left: 40px !important;
            right: auto !important;
            transform: none !important;
            @include m1024 {
              bottom: 30px !important;
            }
            @include m767 {
              bottom: 79px !important;
              left: 20px !important;
            }
          }
        }
        .c-video-button {
          position: static;
          transform: none;
        }
      }
      .media__caption {
        z-index: 4;
        position: absolute;
        bottom: 70px;
        left: 0;
        width: 100%;
        @include m1024 {
          bottom: 30px;
        }
        @include m767 {
          bottom: 16px;
        }
        &Flex {
          @include flex(flex-start, center);
          @include m767 {
            display: block;
          }
        }
        .c-video-button {
          margin-right: 27px;
          @include m767 {
            margin-right: 0;
            margin-bottom: 10px;
          }
        }
        .title-50 {
          color: $white;
        }
      }
    }
    &-media + .main__header-intro {
      @include m1024 {
        padding-top: 40px;
      }
      @include m479 {
        padding-top: 28px;
      }
    }
    &-intro {
      padding-top: 90px;
      padding-bottom: 75px;
      @include m1024 {
        padding-top: 60px;
        padding-bottom: 40px;
      }
      @include m479 {
        padding-top: 40px;
        padding-bottom: 40px;
      }
      .container + .container {
        margin-top: 100px;
        @include m1024 {
          margin-top: 50px;
        }
      }
      .container > .main__header-introFlexText {
        margin-top: -20px;
      }
      &Flex {
        @include flex();
        flex-direction: row-reverse;
        @include m1024 {
          flex-direction: column;
        }
        &--offer {
          margin-top: 20px;
          padding-top: 30px;
          border-top: 3px solid var(--primary-color);
          .intern_website & {
            border-color: $intern;
          }
          @include m1024 {
            flex-direction: column-reverse;
          }
          .main__header-introFlexShare {
            @include m1024 {
              margin-bottom: 0;
            }
          }
          .main__header-introFlexText {
            max-width: 999px;
          }
        }
        &Text {
          max-width: 680px;
          img {
            display: block;
            margin-bottom: 20px;
            max-width: 180px;
          }
        }
        &Share {
          @include m1024 {
            margin-bottom: 26px;
            text-align: right;
          }
        }
      }
      .title-50 {
        max-width: 610px;
      }
      .title-50 + p,
      .title-50 + .paragraph {
        margin-top: 30px;
      }
      .c-quote {
        position: relative;
        max-width: 680px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 95px;
        @include m1024 {
          padding-left: 28px;
        }
        &-text,
        &-text p {
          font-size: 42px;
          line-height: 1.14;
          color: $dark-blue;
          @include m1024 {
            font-size: 25px;
          }
        }
        &:before {
          position: absolute;
          top: -38px;
          left: 0;
          content: "\e914";
          font-size: 82px;
          color: rgba($dark-blue, 0.2);
          @include icomoon();
          @include m1024 {
            top: -28px;
            font-size: 52px;
          }
        }
      }
    }
  }
  &__content {
    &[data-bg="grey"] {
      padding-top: 62px;
      padding-bottom: 75px;
      background-color: $light-grey;
      @include m1024 {
        padding-top: 40px;
        padding-bottom: 45px;
      }
      @include m479 {
        padding-top: 30px;
        padding-bottom: 41px;
      }
    }
    &[data-color="blue"] {
      h2:not([class*="title-"]),
      h3:not([class*="title-"]), .accordion-toggle {
        color: $dark-blue;
      }
      ol:not(.c-group-logos):not(.c-tint-links):not(.c-share-list):not(.c-offer-infos):not(.c-faq-list) li,
      ul:not(.c-group-logos):not(.c-tint-links):not(.c-share-list):not(.c-offer-infos):not(.c-faq-list) li,
      p {
        a {
          color: $blue;
          &.link {
            &:before {
              color: $blue;
            }
          }
        }
      }
      ol:not(.c-group-logos):not(.c-tint-links):not(.c-share-list):not(.c-offer-infos):not(.buttons-list):not(.c-faq-list),
      ul:not(.c-group-logos):not(.c-tint-links):not(.c-share-list):not(.c-offer-infos):not(.buttons-list):not(.c-faq-list) {
        li {
          &:before {
            background-color: $dark-blue;
          }
        }
      }
      blockquote {
        color: $dark-blue;
        &:before {
          color: rgba($dark-blue, 0.2);
        }
      }
    }
    &[data-custom-color="true"] {
      h2:not([class*="title-"]),
      h3:not([class*="title-"]), .accordion-toggle {
        color: inherit;
      }
      ol:not(.c-group-logos):not(.c-tint-links):not(.c-share-list):not(.c-offer-infos):not(.c-faq-list) li,
      ul:not(.c-group-logos):not(.c-tint-links):not(.c-share-list):not(.c-offer-infos):not(.c-faq-list) li,
      p {
        a {
          color: inherit;
          &.link {
            &:before {
              color: inherit;
            }
          }
        }
      }
      ol:not(.c-group-logos):not(.c-tint-links):not(.c-share-list):not(.c-offer-infos):not(.buttons-list):not(.c-faq-list),
      ul:not(.c-group-logos):not(.c-tint-links):not(.c-share-list):not(.c-offer-infos):not(.buttons-list):not(.c-faq-list) {
        li {
          &:before {
            background-color: inherit;
          }
        }
      }
      blockquote {
        color: inherit;
        &:before {
          color: inherit;
        }
      }
    }
    &[data-color="purple"] {
      h2:not([class*="title-"]),
      h3:not([class*="title-"]),
      [class*="title-"], .accordion-toggle {
        color: var(--primary-color);
        .intern_website & {
          color: $intern;
        }
      }
      ol:not(.c-group-logos):not(.c-tint-links):not(.c-share-list):not(.c-offer-infos):not(.buttons-list):not(.c-faq-list),
      ul:not(.c-group-logos):not(.c-tint-links):not(.c-share-list):not(.c-offer-infos):not(.buttons-list):not(.c-faq-list) {
        li {
          &:before {
            background-color: var(--primary-color);
            .intern_website & {
              background-color: $intern;
            }
          }
        }
      }
    }
    strong {
      font-weight: 500;
    }
    h2:not([class*="title-"]) {
      margin-bottom: 30px;
      max-width: 680px;
      @include h4-title();
      color: var(--secondary-color);
      @include m767 {
        margin-bottom: 14px;
        font-size: 25px;
      }
    }
    h3:not([class*="title-"]) {
      margin-bottom: 30px;
      max-width: 680px;
      @include h5-title();
      color: var(--secondary-color);
    }
    h4:not([class*="title-"]) {
      margin-bottom: 16px;
      max-width: 680px;
      @include h5-title();
      font-size: 20px;
      color: $black;
    }
    p + h2,
    p + h3 {
      margin-top: 52px;
      @include m767 {
        margin-top: 40px;
      }
    }
    p + h4 {
      margin-top: 38px;
      @include m767 {
        margin-top: 30px;
      }
    }
    p + .c-button-ctn,
    a + .c-button-ctn,
    .description + .c-button-ctn,
    p + .c-button,
    a + .c-button,
    .description + .c-button {
      margin-top: 62px;
      @include m767 {
        margin-top: 40px;
      }
    }
    .c-button + h2,
    .c-button + h3 {
      margin-top: 80px;
      @include m767 {
        margin-top: 50px;
      }
    }
    ol {
      list-style: decimal;
    }
    ol:not(.c-group-logos):not(.c-tint-links):not(.c-share-list):not(.c-offer-infos):not(.c-faq-list) li,
    ul:not(.c-group-logos):not(.c-tint-links):not(.c-share-list):not(.c-offer-infos):not(.c-faq-list) li,
    p,
    .description {
      font-size: 16px;
      line-height: 1.5;
      a {
        color: var(--primary-color);
        transition: color 0.35s ease-in-out;
        .intern_website & {
          color: $intern;
        }
        @include m1025 {
          &:hover,
          &:focus-within {
            color: $black;
          }
        }
        &.link {
          display: block;
          color: $black;
          font-size: 18px;
          font-weight: 500;
          line-height: 1.33;
          transition: transform 0.35s ease-in-out;
          &:before {
            display: inline-block;
            vertical-align: middle;
            margin-right: 12px;
            font-size: 12px;
            color: var(--primary-color);
            content: "\e90f";
            @include icomoon();
            transition: transform 0.35s ease-in-out;
            .intern_website & {
              color: $intern;
            }
          }
          @include m1025 {
            &:hover,
            &:focus-within {
              transform: translateX(6px);
              &:before {
                transform: translateX(4px);
              }
            }
          }
        }
      }
    }
    ol:not(.c-group-logos):not(.c-tint-links):not(.c-share-list):not(.c-offer-infos):not(.c-faq-list) li,
    ul:not(.c-group-logos):not(.c-tint-links):not(.c-share-list):not(.c-offer-infos):not(.c-faq-list) li,
    p {
      & + p {
        margin-top: 18px;
      }
      & + li {
        margin-top: 14px;
      }
    }
    ol:not(.c-group-logos):not(.c-tint-links):not(.c-share-list):not(.c-offer-infos):not(.buttons-list):not(.c-faq-list),
    ul:not(.c-group-logos):not(.c-tint-links):not(.c-share-list):not(.c-offer-infos):not(.buttons-list):not(.c-faq-list) {
      max-width: 760px;
      margin-left: auto;
      margin-right: auto;
      @include m767 {
        margin-left: 20px;
        margin-right: 0;
      }
      &:not(:first-child) {
        margin-top: 50px;
        @include m991 {
          margin-top: 30px;
        }
      }
      &:not(:last-child) {
        margin-bottom: 50px;
        @include m991 {
          margin-bottom: 30px;
        }
      }
    }
    ul:not(.c-group-logos):not(.c-tint-links):not(.c-share-list):not(.c-offer-infos):not(.buttons-list):not(.c-faq-list) {
      li {
        padding-left: 19px;
        &:before {
          position: relative;
          top: 7px;
          content: "";
          width: 9px;
          height: 9px;
          background-color: var(--secondary-color);
          border-radius: 50%;
          float: left;
          margin-left: -19px;
        }
      }
    }
    /*ul.buttons-list {
            margin-top: 35px;
            margin-bottom: 80px;
            @include m1024 {
                margin-bottom: 60px;
            }
            .c-button {
                position: relative;
                display: block;
                padding: 13px 28px;
                font-family: $fontTitle;
                @include button($white);
                border-radius: 16px;
                border-color: $medium-grey;
                font-size: 24px;
                text-align: left;
                color: var(--secondary-color);
                transition: all .35s ease-in-out !important;
                &:after {
                    overflow: hidden;
                    position: absolute;
                    top: 50%;
                    right: 28px;
                    transform: translateY(-50%);
                    width: 0;
                    font-size: 12px;
                    display: block;
                    content: "\e90f";
                    @include icomoon();
                    color: $white;
                    transition: all .35s ease-in-out;
                }
                &:hover {
                    @include m1025 {
                        color: $white !important;
                        background-color: var(--secondary-color);
                        border-color: var(--secondary-color);
                        &:after {
                            width: 23px;
                        }
                    }
                }
            }
        }*/
    blockquote p {
      @include quote();
    }
    blockquote {
      position: relative;
      max-width: 540px;
      padding-left: 40px;
      margin-left: auto;
      margin-right: auto;
      @include quote();
      color: var(--secondary-color);
      @include m1024 {
        padding-left: 20px;
      }
      &:not(:first-child) {
        margin-top: 100px;
        @include m1024 {
          margin-top: 35px;
        }
      }
      &:not(:last-child) {
        margin-bottom: 75px;
        @include m1024 {
          margin-bottom: 30px;
        }
      }
      &:before {
        position: absolute;
        top: -20px;
        left: 0;
        content: "\e914";
        font-size: 50px;
        color: rgba(var(--secondary-color-rgb), 0.35);
        @include icomoon();
      }
    }
    &Flex {
      @include flex(space-between, flex-start);
      margin-left: -60px;
      margin-right: -60px;
      @include m1279 {
        margin-left: -35px;
        margin-right: -35px;
      }
      @include m1099 {
        margin-left: -20px;
        margin-right: -20px;
      }
      @include m1024 {
        display: block;
        margin-left: 0;
        margin-right: 0;
      }
      > * {
        margin-left: 60px;
        margin-right: 60px;
        @include m1279 {
          margin-left: 35px;
          margin-right: 35px;
        }
        @include m1099 {
          margin-left: 20px;
          margin-right: 20px;
        }
        @include m1024 {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
    &-primary {
      flex: 0 1 57.62%;
      max-width: 680px;
      @include m1024 {
        max-width: 1000px;
      }
      h2 {
        margin-bottom: 12px;
      }
      ol:not(.c-group-logos):not(.c-tint-links):not(.c-share-list):not(.c-offer-infos):not(.c-faq-list) li,
      ul:not(.c-group-logos):not(.c-tint-links):not(.c-share-list):not(.c-offer-infos):not(.c-faq-list) li,
      p {
        & + p {
          margin-top: 22px;
        }
        & + li {
          margin-top: 10px;
        }
      }
      ol:not(.c-group-logos):not(.c-tint-links):not(.c-share-list):not(.c-offer-infos):not(.buttons-list):not(.c-faq-list),
      ul:not(.c-group-logos):not(.c-tint-links):not(.c-share-list):not(.c-offer-infos):not(.buttons-list):not(.c-faq-list) {
        margin-left: 38px;
        @include m767 {
          margin-left: 20px;
        }
        &:not(:first-child) {
          margin-top: 32px;
        }
        &:not(:last-child) {
          margin-bottom: 44px;
        }
      }
      blockquote {
        max-width: 490px;
        &:not(:first-child) {
          margin-top: 68px;
          @include m1024 {
            margin-top: 35px;
          }
        }
        &:not(:last-child) {
          margin-bottom: 42px;
          @include m1024 {
            margin-bottom: 30px;
          }
        }
      }
      .c-button {
        @include button($pink);
      }
    }
    &-aside {
      flex: 0 1 32.2%;
      max-width: 380px;
      text-align: right;
      @include m1099 {
        max-width: 320px;
      }
      @include m1024 {
        max-width: 380px;
        margin-left: auto;
        margin-right: auto;
      }
      @include m768-1024 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-left: -20px;
        margin-right: -20px;
        max-width: 9999px;
        > * {
          flex: 0 1 calc(50% - 40px);
          max-width: calc(50% - 40px);
          margin-left: 20px;
          margin-right: 20px;
        }
      }
      @include m767 {
        margin-top: 52px;
      }
      .c-testimony,
      .c-ambassador,
      .c-offers-map {
        &:not(:first-child) {
          margin-top: 36px;
        }
      }
      .c-offers-map {
        min-height: 503px;
      }
    }
  }
}

.main__nomargin .margincontrol{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.main__nomargin .paddingcontrol, .main__nomargin .flexible{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}