.c-cards + .c-tint {
    margin-top: 55px;
}

.c-tint {
    position: relative;
    overflow: hidden;
    &[data-bg="grey"] {
        padding-top: 75px;
        padding-bottom: 60px;
        background-color: $light-grey;
        @include m767 {
            padding-top: 45px;
            padding-bottom: 45px;
        }
    }
    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {

    }
    &Flex {
        @include flex(flex-start, center);
        margin-left: -15px;
        margin-right: -15px;
        @include m767 {
            display: block;
        }
        > * {
            margin-left: 15px;
            margin-right: 15px;
        }
    }
    &-links {
        @include flex(flex-start, center);
        @include m767 {
            margin-top: 20px;
        }
        li {
            & + li {
                margin-left: 30px;
            }
        }
        a {
            display: block;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 2px solid var(--secondary-color);
            background-color: var(--secondary-color);
            text-align: center;
            color: $white;
            transition: background-color .35s ease-in-out;
            @include m767 {
                width: 32px;
                height: 32px;
            }
            @include m1025 {
                &:hover, &:focus-within {
                    background-color: transparent;
                    outline: 0;
                    i {
                        color: var(--secondary-color);
                    }
                }
            }
            i {
                position: relative;
                font-size: 16px;
                line-height: 36px;
                transition: color .35s ease-in-out;
                @include m767 {
                    font-size: 12px;
                    line-height: 28px;
                }
                &.icon-linkedin {
                    top: -1px;
                    font-size: 20px;
                    @include m767 {
                        top: 0;
                        font-size: 15px;
                    }
                }
                &.icon-facebook {
                    font-size: 22px;
                    @include m767 {
                        font-size: 16px;
                    }
                }
            }
        }
    }
    &-posts {
        margin-top: 50px;
        @include m1024 {
            margin-top: 22px;
        }
    }
    .grid {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(calc(25% - 20px), 1fr));
        grid-auto-rows: 5px;
        @include m1199 {
            grid-template-columns: repeat(auto-fill, minmax(calc(33.33% - 20px), 1fr));
        }
        @include m1024 {
            display: block;
        }
    }

    &-post {
        border: solid 1px #d8d8d8;
        background-color: $white;
        text-align: left;
    }

    .post__media {
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
    .post__content {
        padding: 25px 22px;
        @include m767 {
            padding: 20px 18px;
        }
        &-text {
            font-family: $fontText;
            font-size: 11px;
            line-height: 1.3;
        }
        &-footer {
            @include flex(space-between, center);
            margin-top: 30px;
            .footer__author {
                @include flex(flex-start, center);
                img {
                    display: block;
                    width: 38px;
                    height: 38px;
                    border: solid 1px #d8d8d8;
                }
                &-text {
                    margin-left: 8px;
                    font-family: $fontText;
                    font-size: 10px;
                    font-weight: 600;
                    line-height: 1.15;
                    color: $black;
                    div {
                        font-weight: 400;
                        color: #a0a0a0;
                    }
                }
            }
            .footer__date {
                font-family: $fontText;
                font-size: 11px;
                font-weight: 500;
                color: $dark-grey;
                text-align: right;
            }
        }
    }
}