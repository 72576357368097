.c-group {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 55px;
    @include m991 {
        padding-top: 35px;
        padding-bottom: 35px;
    }
    &[data-bg=grey] {
        padding-top: 85px;
        padding-bottom: 85px;
        background-color: $light-grey;
        @include m991 {
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }
    &-intro {
        font-size: 25px;
        @include m1024 {
            font-size: 18px;
        }
        span:first-child {
            display: block;
            margin-bottom: 8px;
        }
        span:last-child {
            font-weight: 500;
        }
    }
    &-logos {
        margin-top: 40px;
        @include flex(space-between, center);
        margin-left: -15px;
        margin-right: -15px;
        @include m991 {
            justify-content: center;
            flex-wrap: wrap;
        }
        > * {
            margin-left: 15px;
            margin-right: 15px;
            height: 43px;
            min-width: 95px;
            flex: auto;
            @media all and (-ms-high-contrast: none) {
                flex: 1;
            }
            @include m991 {
                margin: 20px 15px;
                height: auto;
            }
            @include m991 {
                min-width: 1px;
                max-width: calc(25% - 40px);
            }
            @include m767 {
                max-width: calc(33.33% - 40px);
            }
            @include m479 {
                max-width: calc(50% - 40px);
            }
        }
        a {
            @include flex(center, center, column);
            height: 100%;
            width: 100%;
            @include m1025 {
                &:hover, &:focus-within {
                    img {
                        //transform: scale(1.1);
                        filter: grayscale(.8);
                    }
                }
            }
        }
        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            transition: all .35s ease-in-out;
        }
    }
}