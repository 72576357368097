.t-404 {
    .header {
        &Bottom {
            display: none;
        }
    }
    .main {
        @include flex(center, center);
        min-height: calc(100vh - (284px + 83px)); //footer + header
        text-align: center;
        background-color: $pink;
        padding-top: 50px;
        padding-bottom: 50px;

        @include m1199 {
            min-height: calc(100vh - (472px + 56px));
        }

        &:before {
            position: absolute;
            top: 83px;
            left: 0;
            content: '';
            display: block;
            width: 100%;
            height: 350px;
            background-image: linear-gradient(to top, rgba(var(--primary-color-rgb), 0), rgba(var(--primary-color-rgb), .8) 76%, rgba(var(--primary-color-rgb), .98));
            @include m1199 {
                top: 56px;
            }
        }

        .container {
            > * {
                position: relative;
                z-index: 1;
            }
        }

        .title-130 {
            color: $white;
        }
        p {
            font-family: $fontTitle;
            font-size: 28px;
            color: $white;
        }
    }
    .c-button {
        margin-top: 50px;
        @include button($white);
        color: $pink;
    }
}