.flexible {
    padding-top: 85px;
    padding-bottom: 85px;
    @include m991 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    &[data-bg=grey] {
        background-color: $light-grey;
    }
}