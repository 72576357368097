//
// Modals
// --------------------------------------------------

// .c-modal-open      - body class for killing the scroll
// .c-modal           - container to scroll within
// .c-modal-dialog    - positioning shell for the actual modal
// .c-modal-content   - actual modal w/ bg and corners and shit

// Container that the modal scrolls within
.c-modal {
  position: fixed;
  @include flex(center, center);
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  display: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: 100vh;

  @include m991 {
    align-items: flex-start;
  }

  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;

  &.show {
    display: flex;
  }

  // When fading in the modal, animate it to slide down
  &.fade .c-modal-dialog {
    // transform: translate(0, -25%);
    transition: transform 0.3s ease-out;
    height: 100%;
    display: flex;
  }
  &.show .c-modal-dialog {
    transform: translate(0, 0);
  }

  &--alert {
    .c-modal-dialog {
      max-width: 520px;
    }
  }
  &--cv {
    .c-modal-dialog {
      max-width: 1010px;
    }
  }
  &-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($black, 0.5);
    // Fade for backdrop
    &.fade {
      opacity: 0;
    }
    &.show {
      opacity: 1;
    }
  }
  &-dialog {
    position: relative;
    width: calc(100% - 40px);
    max-width: 455px;
  }
  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
    // counteract the pointer-events: none; in the .modal-dialog
    pointer-events: auto;
    border-radius: 22px;
    border: solid 1px $light-grey;
    background-color: $white;
    // Remove focus outline from opened modal
    outline: 0;
    overflow: scroll;
    align-self: center;
    max-height: 80vh;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }
  &-close {
    z-index: 3;
    position: absolute;
    top: 28px;
    right: 22px;
    font-size: 15px;
    transition: transform 0.4s ease-in-out;
    i {
      color: $black;
    }
    @include m1025 {
      &:hover,
      &:focus-within {
        transform: rotate(90deg);
      }
    }
  }
  &-body {
    position: relative;
    padding: 58px 60px 40px;
    @include m767 {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .title-30 {
    margin-bottom: 38px;
    color: $black;
  }

  .reset-password-explanations__container {
    margin: 15px auto;
    p,
    li {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
    }
    ul {
      margin: 10px auto;
      li {
        color: #707070;
        margin-left: 20px;
        &:before {
          content: "\2713";
          display: inline-block;
          font-size: 14px;
          margin-right: 10px;
        }
      }
    }
  }

  .register-password-explanations__container {
    margin: 15px auto;
    p,
    li {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
    }
    ul {
      margin: 10px auto;
      li {
        color: #707070;
        margin-left: 20px;
        &:before {
          content: "\2713";
          display: inline-block;
          font-size: 14px;
          margin-right: 10px;
        }
      }
    }
  }

  &-buttons {
    li + li {
      margin-top: 14px;
    }
  }

  h4,
  .c-modal-sep span:first-child {
    font-family: $fontTitle;
    font-size: 20px;
  }

  &-sep {
    margin-top: 28px;
    margin-bottom: 25px;
    @include flex(flex-start, center);
    span {
      &:first-child {
        padding-right: 25px;
        text-transform: uppercase;
      }
      &:last-child {
        flex: 1;
        height: 1px;
        background-color: $black;
      }
    }
  }
  &-button {
    margin-top: 25px;
  }
  &-link {
    margin-top: 14px;
    font-family: $fontTitle;
    font-size: 17px;
    color: $pink;
    border-bottom: 1px solid currentColor;
    transition: border-color 0.4s ease-in-out;
    @include m1025 {
      &:hover,
      &:focus-within {
        border-bottom-color: transparent;
      }
    }
  }
  p + .c-form {
    margin-top: 25px;
  }
  h4 + .c-form {
    margin-top: 16px;
  }
  .c-form {
    &-input,
    &-selectCtn {
      &:not(:first-child) {
        margin-top: 14px;
      }
    }
    a {
      display: inline-block;
      margin-top: 6px;
      margin-left: 20px;
      font-family: $fontText;
      font-size: 12px;
      font-weight: 500;
      border-bottom: 1px solid currentColor;
      transition: border-color 0.4s ease-in-out;
      @include m1025 {
        &:hover,
        &:focus-within {
          border-bottom-color: transparent;
        }
      }
    }
  }
}

// Measure scrollbar width for padding body during modal show/hide
.c-modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.success {
  text-align: center;
  .title-30 {
    margin-bottom: 20px;
    .icon-check {
      color: var(--primary-color);
    }
  }
}
