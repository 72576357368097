.t-home {
    .c-job-sectors + * {
        padding-top: 30px;
    }
    .home-jobs + *,
    .home-ambassador + *,
    .home-ambassadors + *,
    .home-career + *,
    .home-testimony + * {
        padding-top: 0;
    }
    .home-testimony + .home-ambassadors {
        padding-top: 60px;
    }
    .c-testimonies {
        padding-top: 60px;
        border-top: 9px solid $light-grey;
    }
}

.home-career, .home-testimony, .home-jobs {
    padding-top: 85px;
    padding-bottom: 85px;
    &Flex {
        @include flex(space-between, center);
        @include m991 {
            display: block;
        }
    }
    &-content {
        flex: 1;
        @media all and (-ms-high-contrast: none) {
            flex: auto;
        }
        margin-left: auto;
        margin-right: auto;
        @include m991 {
            margin-bottom: 65px;
        }
    }
    &-item {
        max-width: 380px;
        flex: 0 1 380px;
        @include m991 {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.home-jobs, .home-testimony {
    padding-bottom: 35px;
}

.home-career, .home-testimony {
    &-content {
        max-width: 580px;
        blockquote {
            position: relative;
            font-family: $fontText;
            font-size: 42px;
            font-style: italic;
            line-height: 1.14;
            color: $green;
            @include m1024 {
                font-size: 25px;
            }
            &:before {
                position: absolute;
                top: -45px;
                left: -100px;
                content: "\e914";
                font-size: 83px;
                color: rgba($green, .35);
                @include icomoon();
                @include m1024 {
                    top: -20px;
                    left: -7px;
                    font-size: 52px;
                }
            }
        }
        blockquote + p {
            margin-top: 15px;
        }
        blockquote, p {
            @include m1024 {
                padding-left: 28px;
            }
        }
        p {
            font-size: 22px;
            @include m1024 {
                font-size: 18px;
            }
        }
        .c-button {
            margin-top: 28px;
            @include button($green);
        }

    }
    &-button {
        @include m991 {
            text-align: center;
        }
    }
}

.home-testimony {
    &-item {
        margin-left: 60px;
        @include m991 {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.home-career {
    .title-50 {
        color: $green;
    }
    &Flex {
        margin-top: 60px;
        @include m991 {
            margin-top: 35px;
        }
    }
    &-content {
        margin-right: 0;
        @include m991 {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 0;
        }
    }
    &-item {
        margin-right: 60px;
        padding: 53px 40px 30px;
        background-color: $green;
        border-radius: 22px;
        color: $white;
        text-align: left;
        @include m991 {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 60px;
        }
        @include m767 {
            padding: 30px 28px;
            margin-bottom: 85px;
        }

        .title-40 {
            color: $white;
            line-height: 1;
            @include m767 {
                font-size: 25px;
            }
        }
        .title-40 + * {
            margin-top: 25px;
        }
        p {
            font-weight: 500;
            @include m767 {
                font-size: 14px;
            }
        }
        .c-button {
            @include button($white);
            color: $green;
            margin-top: 35px;
        }
    }
}

.home-jobs {
    &Flex {
        align-items: flex-start;
    }
    &-content {
        max-width: 680px;
        margin-left: 0;
        margin-right: 60px;
        @include m991 {
            margin-left: auto;
            margin-right: auto;
        }
        .home-jobs-button {
            text-align: center;
        }
        .c-button {
            @include button(var(--secondary-color));
        }
    }
    ul.buttons-list {
        margin-top: 60px;
        margin-bottom: 32px;
        @include m767 {
            margin-top: 35px;
        }
        li + li {
            margin-top: 18px;
        }
    }
}

.home-ambassadors, .home-ambassador {
    padding-top: 85px;
    padding-bottom: 85px;
    .title-50 {
        @include flex(flex-start, center);
        color: $blue;
        @include m767 {
            display: block;
        }
        span {
            font-size: 50px;
            @include m1024 {
                font-size: 30px;
            }
        }
        img {
            position: relative;
            top: 4px;
            max-width: 160px;
            margin-left: 20px;
            @include m767 {
                margin-left: 0;
                margin-top: 20px;
            }
        }
    }
    &Flex,
    &-sliderCtn {
        position: relative;
        margin-top: 75px;
        &:before, &:after {
            position: absolute;
            content: "\e914";
            color: rgba($blue, .35);
            @include icomoon();
        }
        &:before {
            left: 0;
            top: -55px;
            font-size: 108px;
            @include m767 {
                top: -45px;
                font-size: 93px;
            }
        }
        &:after {
            right: 0;
            bottom: -45px;
            transform: rotate(180deg);
            font-size: 83px;
            @include m767 {
                display: none;
            }
        }
    }
    &Flex {
        @include flex(space-between, initial);
        margin-left: -10px;
        margin-right: -10px;
        > * {
            flex: 1 1 0;
            margin-left: 10px !important;
            margin-right: 10px !important;
        }
    }
    &-button {
        margin-top: 50px;
        text-align: center;
    }
    .c-button {
        @include button($blue);
    }
    .c-ambassador {
        &-img {
            &:before {
                display: none;
            }
            &Crop {
                margin-top: 0;
            }
        }
    }
}

.bodyContainer {
    .home-ambassadors {
        .swiper-container:not(.swiper-container-multirow-column) {
            @include m1024 {
                width: 100%;
                padding-right: 0 !important;
            }
            @include m767 {
                width: calc(100% + 20px);
                padding-right: 35px !important;
            }
            .swiper-wrapper {
                .swiper-slide {
                    @include m1024 {
                        width: calc(50% - 11px) !important;
                    }
                    @include m767 {
                        width: 100% !important;
                    }
                }
            }
        }
        .c-ambassador {
            max-width: 380px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.home-ambassador {
    .c-ambassador {
        max-width: 580px;
        padding-left: 75px;
        box-sizing: content-box;
        margin-left: auto;
        margin-right: auto;
        @include m767 {
            padding-left: 0;
        }
        &-title {
            max-width: 480px;
            font-size: 40px;
            @include m1024 {
                font-size: 30px;
            }
        }
        > img {
            max-width: 160px;
            margin-bottom: 20px;
            @include m767 {
                margin-top: 20px;
                margin-bottom: 75px;
            }
        }
        &-img {
            float: left;
            margin-right: 33px;
            @include m767 {
                float: none;
                margin-right: 0;
            }
            &:before {
                display: block;
                position: absolute;
                top: -55px;
                left: -75px;
                content: "\e914";
                font-size: 108px;
                color: rgba($blue, .2);
                @include icomoon();
                @include m767 {
                    left: 0;
                    top: -45px;
                    font-size: 93px;
                }
            }
        }
        &-button {
            text-align: left;
            @include m767 {
                text-align: center;
            }
        }
    }
}