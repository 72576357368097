.bodyContainer .c-offers {
  position: relative;
  background-color: $white;
  padding-top: 80px;
  padding-bottom: 75px;
  @include m767 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  &:first-child {
    padding-top: 50px;
    @include m767 {
      padding-top: 30px;
    }
  }
  .title-50 {
    color: var(--primary-color);
    .intern_website & {
      color: $intern;
    }
  }

  &FlexJustify {
    @include flex(space-between, center, row, wrap);
    @include m991 {
      display: block;
    }
    .c-offers-title {
      @include m992 {
        margin-bottom: 20px;
        padding-right: 50px;
      }
    }
    .c-filters {
      @include m992 {
        display: flex;
        // justify-content: flex-end;
        flex: 1;
      }
    }
  }
  .c-search-offersRemove {
    padding-bottom: 0;
    .container {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .c-filters {
    @include m991 {
      margin-top: 32px;
    }
  }
  &Flex {
    position: relative;
    @include flex(flex-start, initial);
    //height: 515px;
    //height: 540px;
    //height: 565px;
    //height: 520px;
    height: 565px;
    margin-top: 30px;
    @include m1024 {
      // height: 540px;
      height: 565px;
    }
    @include m767 {
      display: block;
      height: auto;
    }
    .swiper-container {
      position: static !important;

      .swiper-button-prev {
        bottom: -42px !important;
        right: calc(80px + 16px) !important;
      }
      .swiper-button-next {
        bottom: -42px !important;
        right: 0 !important;
      }
      &.swiper-container-horizontal > .swiper-scrollbar {
        bottom: -38px !important;
        right: 12px;
      }
    }
  }
  &-map {
    overflow: hidden;
    min-width: 380px;
    margin-right: 20px;
    border-radius: 27px;
    border: solid 1px $medium-grey;
    background-color: $light-grey;
    height: calc(100% - 30px);
    margin-top: 20px;
    @include m1024 {
      display: none;
    }
  }
  &-map + .c-offers-slider {
    @include m1025 {
      max-width: calc(100% - 400px);
    }
  }
  &-slider {
    flex: 1;
    .c-offer {
      margin-top: 0;
    }
  }
  &-noresults {
    margin-top: 20px;
    margin-bottom: 60px;
    padding: 30px 20px;
    background-color: $light-grey;
    border-radius: 19px;
    text-align: center;
    font-size: 20px;
    color: $dark-grey;
  }
  &-button {
    margin-top: 30px;
    // margin-top: -35px;
    text-align: center;
    @include m1024 {
      margin-top: 30px;
    }
    .c-button {
      position: relative;
      z-index: 5;
      .intern_website & {
        background-color: $intern;
        border-color: $intern;
        @include m1025 {
          &:hover,
          &:focus-within {
            color: $intern;
            background-color: transparent;
          }
        }
      }
    }
  }

  .c-offer {
    @include m767 {
      width: 100%;
      margin-top: 0;
    }
    &Link {
      //padding-bottom: 60px;
      //@include m1025 {
      //    padding-bottom: 38px;
      //}
      padding-bottom: 38px;
      @include m768 {
        padding: 26px 20px;
        height: 100%;
      }
    }
    &-title {
      @include m768 {
        font-size: 22px;
      }
    }
    &-brand {
      @include m768 {
        font-size: 11.5px;
      }
    }

    &-infos {
      padding-right: 44px;
      @include m768 {
        display: flex;
        margin-top: 5px;
      }

      li {
        @include m768 {
          font-size: 13px;
          i {
            margin-right: 6px;
            font-size: 15px;
          }
          &:last-child {
            width: 100%;
          }
        }
      }
    }
  }
}

.bodyContainer {
  .c-offers {
    .swiper-container-horizontal {
      > .swiper-pagination-bullets {
        @include m768 {
          display: none;
        }
      }
      > .swiper-scrollbar {
        @include m768 {
          display: block;
        }
      }
    }
    .swiper-container:not(.swiper-container-multirow-column) {
      .swiper-wrapper {
        .swiper-slide {
          text-align: left;
        }
      }
    }
  }
}
