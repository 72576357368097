.t-job-sectors-list {
  .main {
    &__content {
      padding-bottom: 85px;
      @include m1024 {
        padding-bottom: 60px;
      }
      &Flex {
        &.job-filter {
          .c-form-selectCtn {
            position: relative;
            top: 7px;
            min-width: 380px;
            @include m1024 {
              margin-top: 16px;
              min-width: 1px;
            }
          }
        }
        &.job-list {
          margin-top: -8px;
          justify-content: flex-start;
          align-items: initial;
          flex-wrap: wrap;
          margin-left: -10px;
          margin-right: -10px;
          @include m767 {
            margin-top: 30px;
            margin-left: 0;
            margin-right: 0;
          }
          > * {
            flex: 1 1 0;
            max-width: calc(33.33% - 20px);
            min-width: calc(33.33% - 20px);
            margin-left: 10px;
            margin-right: 10px;
            @include m1199 {
              max-width: calc(50% - 20px);
              min-width: calc(50% - 20px);
            }
            @include m991 {
              max-width: calc(100% - 20px);
              min-width: calc(100% - 20px);
            }
          }
        }
      }
    }
  }
}
