@import 'mixins.scss';

$colorBackground: #9159a4;
$internColorBackground: #655679;
$colorTexte: #fff;

@mixin elevator-btn {
    background-color: transparent;
    border: solid 2px transparent;
    cursor: pointer;
    padding: 6px 47px 11px;
    transition: border-color 0.35s ease;
    border-radius: 35px;
    position: relative;
    overflow: hidden;

    &:hover, &.activated, &:focus {
        border: solid 2px $colorTexte;
        outline: none;
    }

    &:hover,
    &:focus,
    &:active {
        outline: none;
    }
}