.t-search-list {
    .main__header-intro .container > .main__header-introFlexText {
        margin-top: 0;
    }
    .c-search--results[data-bg="grey"] {
        margin-top: 0;
    }
    .search__results {
        margin: 15px auto 50px auto;
        @include m1025 {
            margin: 40px auto 100px auto;
        }
    }
    .c-search-button {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 50px auto;
        .c-button {
            .intern_website & {
                background-color: $intern;
                border-color: $intern;
                @include m1025 {
                    &:hover, &:focus-within {
                        background-color: transparent;
                        color: $intern;
                    }
                }
            }
        }
    }
}