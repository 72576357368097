.bodyContainer {
    .c-news {
        .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
            bottom: 85px;
            @include m991 {
                bottom: 0;
            }
        }
        .swiper-container-horizontal > .swiper-pagination-bullets {
            @include m1025 {
                display: flex;
            }
        }
        .swiper-container:not(.swiper-container-multirow-column) {
            width: 100%;
            padding-right: 0 !important;
            padding-bottom: 0 !important;
            @include m1024 {
                width: 100%;
                padding-right: 0 !important;
            }
            @include m767 {
                width: calc(100% + 40px);
                margin-left: -20px;
                margin-right: -20px;
            }
            .swiper-wrapper {
                .swiper-slide {
                    width: 100% !important;
                }
            }

        }
    }
}

.c-news {
    padding-top: 85px;
    padding-bottom: 85px;

    @include m991 {
        padding-top: 50px;
        padding-bottom: 35px;
    }
    &-slider {
        margin-top: 36px;
    }
    &Item {
        position: relative;
        padding-bottom: 135px;
        width: 100%;
        @include m991 {
            padding-bottom: 50px;
        }
        &-img {
            width: 95%;
            margin: auto;

            img {
                width: 100%;
                height: auto;
            }
        }
        &-content {
            bottom: 0;
            right: 0;
            width: 95%;
            margin: auto;
            padding: 25px 100px 30px 100px;
            background-color: $white;
            border-bottom-right-radius: 22px;
            border-bottom-left-radius: 22px;
            border: solid 5px var(--secondary-color);
            border-top: solid 0px transparent;
            color: $white;
            text-align: left;
            display: flex;
            column-gap: 60px;

            @include m1024 {
                display: block;
                padding: 25px 30px 30px 30px;
            }


            &-right {
                display: flex; 
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
            }

            .title-40 {
                color: var(--secondary-color);
                line-height: 1;
                min-width: 340px; 
                max-width: 40%;

                @include m1024 {
                    max-width: 100%; 
                    margin-bottom: 20px; 
                }

                @include m767 {
                    font-size: 24px;
                }
            }
            
            p {
                color: var(--secondary-color);
                font-weight: 500;
                font-size: 18px;
                @include m767 {
                    font-size: 14px;
                }
            }
            .c-button {
                @include button(var(--secondary-color));
                color: $white;
            }
            
            p + .c-button {
                margin-top: 35px;
            }
        }
    }
}