$colors: (
        "darkPurple": var(--secondary-color),
        "appleGreen": $apple-green,
        "pink": $pink,
);

.c-push {
    padding: 30px 38px 26px;
    border-radius: 22px;
    border: solid 1px $medium-grey;
    background-color: $white;
    text-align: center;
    @include m1099 {
        padding: 25px 28px;
    }
    @include m767 {
        padding: 30px 8px;
        margin-left: -10px;
        margin-right: -10px;
        width: calc(100% + 20px);
    }
    @each $name, $color in $colors {
        &--#{$name} {
            .c-push {
                &-title, &-glassdoor {
                    span, strong {
                        color: #{$color};
                    }
                }
            }
            .c-button {
                @include button(#{$color});
            }
        }
    }
    &--elevator {
        padding-top: 75px;
        padding-bottom: 40px;
        border-color: var(--primary-color);
        background-color: var(--primary-color);
        background-image: url('#{$imgPath}sticky-wave-transparent.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top 35px center;
        .intern_website & {
            border-color: $intern;
            background-color: $intern;
            background-image: url('#{$imgPath}sticky-wave-transparent.png');
        }
        .c-push {
            &-title {
                font-family: $fontTitle;
                font-size: 55px;
                line-height: 1;
                letter-spacing: -2.2px;
                color: $white;
                @include m1099 {
                    font-size: 45px;
                }
                @include m767 {
                    font-size: 40px;
                }
            }
        }
        p {
            margin-top: 22px;
            color: $white;
            font-size: 18px;
            @include m767 {
                margin-top: 50px;
            }
        }
        .c-button {
            @include button($white);
            color: var(--primary-color);
            @include m767 {
                margin-top: 24px !important;
            }
        }
    }
    &:not(:first-child) {
        margin-top: 36px;
    }
    &-title {
        font-family: $fontText;
        font-size: 22px;
        font-weight: 500;
        line-height: 1.36;
        color: $black;
        @include m767 {
            font-size: 20px;
        }
        &.c-push-title--icon {
            max-width: 266px;
            margin-left: auto;
            margin-right: auto;
            @include flex(center, center);
            span, strong {
                font-weight: 500;
                color: $black;
                text-align: left;
                @include m767 {
                    text-align: center;
                }
            }
        }
        span, strong {
            font-weight: 700;
            @include m767 {
                display: block;
            }
        }
        .icon-scoring {
            display: block;
            margin-right: 23px;
            font-size: 98px;
            @include m767 {
                display: none;
            }
        }
    }
    &-glassdoor {
        margin-top: 44px;
        margin-bottom: 16px;
        font-family: $fontText;
        font-size: 16px;
        font-weight: 500;
        color: $black;
        span, strong {
            font-size: 30px;
            font-weight: 700;
        }
    }
    .c-button {
        margin-top: 15px;
    }
}