.c-testimony {
    display: block;
    padding-bottom: 65px;
    text-align: left;
    @include m767 {
        margin-left: -20px;
        margin-right: -20px;
        width: calc(100% + 40px);
    }

    &:not(.f-video-content) {
        @include m1025 {
            &:hover, &:focus-within {
                outline: 0 !important;
                .c-quote {
                    border-color: $medium-grey;
                    background-color: $white;
                    &:before {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            }
        }
    }

    .c-video {
        position: relative;
        z-index: 2;
        margin-left: 38px;
        margin-right: 38px;
        @include m1099 {
            margin-left: 25px;
            margin-right: 25px;
        }
        @include m767 {
            margin-left: 20px;
            margin-right: 20px;
        }
        &-button {
            .icon-play {
                font-size: 58px;
                @include m767 {
                    font-size: 51px;
                }
            }
        }
    }

    .c-video + .c-quote {
        margin-top: -90px;
        padding-top: 119px;
        @include m767 {
            margin-top: -84px;
            padding-top: 110px;
        }
    }

    .c-quote {
        position: relative;
        padding: 43px 38px 62px;
        border-radius: 18px;
        background-color: $light-grey;
        border: 1px solid $light-grey;
        transition: all .35s ease-in-out;
        @include m1199 {
            padding: 30px 25px 40px;
        }
        @include m767 {
            padding: 30px 20px 32px;
            border-radius: 16px;
        }

        &:before {
            opacity: 0;
            content: "\e912";
            display: block;
            position: absolute;
            bottom: 16px;
            right: 16px;
            width: 44px;
            height: 44px;
            border-radius: 50%;
            background-color: $dark-blue;
            text-align: center;
            color: $white;
            font-size: 15px;
            transform: scale(.2);
            transition: all .3s ease-in-out .1s;
            @include icomoon();
            line-height: 44px;
        }

        &-author {
            color: $dark-blue;
        }

        &-text + .c-quote-author {
            margin-top: 30px;
        }

        &:after {
            display: block;
            position: absolute;
            bottom: -51px;
            right: 36px;
            content: "\E914";
            font-size: 82px;
            color: rgba($dark-blue, .2);
            transform: rotate(180deg);
            /* use !important to prevent issues with browser extensions that change fonts */
            font-family: 'icomoon' !important;
            //speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;

            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;

            @include m1199 {
                font-size: 58px;
                bottom: -30px;
            }
            @include m767 {
                bottom: -36px;
                right: 40px;
                font-size: 54px;
            }
        }
        &-text, &-text p {
            font-size: 18px;
            @include m767 {
                font-size: 16px;
            }
        }
    }
}

[data-bg="grey"] {
    .c-testimony {
        .c-quote {
            background-color: $white;
            border-color: $white;
        }
    }
}