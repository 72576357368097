.c-map {
    width: 100%;
    height: 100%;
    min-height: 503px;
    &-popup {
        .c-offer {
            &-title {
                font-size: 16px;
                margin-bottom: 8px;
                padding-bottom: 8px;
            }
            &-brand {
                font-size: 12px;
            }
            &-brand + .c-offer-infos {
                li {
                    margin-top: 8px;
                }
            }
            &-infos {
                li {
                    font-size: 12px;
                    @include m1024 {
                        margin-bottom: 10px;
                    }
                    i {
                        margin-right: 6px;
                        font-size: 16px;
                    }
                }
            }
        }
        .c-button {
            margin-top: 10px;
            padding: 3px 14px 4px;
            font-size: 12px;
            color: $white;
            &:hover, &:focus-within {
                color: var(--primary-color);
            }
        }
    }

}

.bodyContainer {
    .leaflet-top, .leaflet-bottom,
    .leaflet-control {
        z-index: 401;
    }
    .leaflet-popup {
        min-width: 280px;
    }
}